import React, { createContext, useState, useContext } from 'react';

// Crea il Context
const CurrentCliContext = createContext();

// Crea il Provider
export const CurrentCliProvider = ({ children }) => {
  const [currentCli, setCurrentCli] = useState(sessionStorage.getItem('CurrentCli') || '');

  const updateCurrentCli = (cli) => {
    setCurrentCli(cli);
    console.log('ciaoo')
    sessionStorage.setItem('CurrentCli', cli); // Salva il parametro in sessionStorage
  };

  return (
    <CurrentCliContext.Provider value={{ currentCli, updateCurrentCli }}>
      {children}
    </CurrentCliContext.Provider>
  );
};

// Crea un hook per consumare il Context
export const useCurrentCli = () => useContext(CurrentCliContext);
