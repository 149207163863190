import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import loading from '../images/loading.json'
const LoadingTab = () => {
  return (
    <div style={{marginLeft:'auto',marginRight:'auto',marginTop:'auto',marginBottom:'auto'}}>
    <Player
src={loading}
className="player"
loop
autoplay
speed={1}
style={{width:'10rem',height:'10rem'}}
/>
<h4 className='normal-cool' style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',marginTop:'-5px',fontSize:'12px'}}>Caricamento in corso, attendere...</h4>
 </div>
  )
}

export default LoadingTab