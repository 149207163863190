import React,{useEffect, useState} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import firebase from "firebase";
import { getUtente,formatData,checkWriteS,checkDeleteS } from '../EngineClienti/engineClienti';
import { getInterventi,getInterventiCliente } from '../EngineOffritore/engineOffritore';
import LoopIcon from '@material-ui/icons/Loop';
import '../general.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import GenerazionePdf from '../GenerazionePdf';
import SubActivity from '../Popups/SubActivity';
import DeleteIcon from '@mui/icons-material/Delete';
import NavBar2 from '../NavBar/NavBar2';
import Notifiche from '../Notifiche/Notifiche';
import WarningIcon from '@mui/icons-material/Warning';
import LoadingScreen from '../LoadingScreen';
import LoadingTab from '../Caricamenti/LoadingTab';
import { useCurrentCli } from '../../CurrentCliContext';

const InterventiOffritore = ({history}) => {

      const { currentCli,updateCurrentCli } = useCurrentCli();  // Ottieni il cliente selezionato dal Context
  

    const [sedi,setSedi] = useState([]) //lista sedi
    const [interventi,setInterventi] = useState([]) //lista interventi
    const [clienti,setClienti] = useState([]) //lista fornitori
    const [user,setUser] = useState(null) //info utente 
    const [triggerSub, setTriggerSub] = useState(false);//trigger popup sottoaccount
    const [infoSub, setInfoSub] = useState(); 

        ///////FILTRI///////

        const [clienteF,setClienteF] = useState('default')
        const [statoF,setStatoF] = useState('default')
        const [findInput,setFindInput] = useState('')

    const db = firebase.firestore()
    const auth = firebase.auth()
    const storage = firebase.storage();
    var storageRef = storage.ref();
    
    const listaI = JSON.parse(sessionStorage.getItem('interventi'));
    const listaSe = JSON.parse(sessionStorage.getItem('sedi'));
    const listaC = JSON.parse(sessionStorage.getItem('clienti'));

        //sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [loading,setLoading] = useState(false)
  const [lTab,setLTab] = useState(false)

    useEffect(() => {
      if(user!==null){
        if(user.Current_Int){

      if(user.Current_Int===interventi.length){
        sessionStorage.setItem('interventi', JSON.stringify(interventi));
        sessionStorage.setItem('sedi', JSON.stringify(sedi));
        sessionStorage.setItem('clienti', JSON.stringify(clienti));

      }
    }else{
      db.collection('Users').doc(user.Email).update({
        Current_Int: interventi.length
      })
    }
    }  
    }, [interventi]);

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])


         //prende dati nuovo utente singolo
              function changeUser(){
                console.log('ciao')
                if(user!==null){
            
                 setLTab(true)
            
                 const compare = currentCli?currentCli:user.DefaultCli
            
                 console.log('grammu',currentCli)
            
                  if (compare === "Tutti") {
                    console.log("Mostro i dati di tutti i clienti");
              
                    getInterventi(db,user.Email,setSedi,setClienti,setInterventi)
      
                    setLTab(false)
            
                  }else{
                  console.log('zizza')
                  // Controlla se i dati del cliente sono già in sessionStorage
                  const cachedData = sessionStorage.getItem(`interventiCliente_${compare}`);
            
                  if (cachedData) {
                    console.log("Dati caricati dalla cache:", compare);
                    const data = JSON.parse(cachedData);
                    setInterventi(data.interventi)
                    setLTab(false)
  
                    setSedi(data.sedi)
                    setClienti(data.clienti)
                  } else {
                    console.log("Scarico i dati dal DB per", compare);
                    getInterventiCliente(db,user.Email,compare).then((data) => {
                      setInterventi(data.interventi)
                    setLTab(false)
  
                    setSedi(data.sedi)
                    setClienti(data.clienti)
              
                      // Salva i dati nella cache per il cliente selezionato
                      sessionStorage.setItem(
                        `interventiCliente_${compare}`,
                        JSON.stringify(data)
                      );
                    });
                  }
                }
                }
              }
            
              //controllo se utente selezionato è quello attuale
              function check(){
                const elementiz= [...listaI?listaI:interventi]
            
                const compare = currentCli?currentCli:user.DefaultCli
            
                console.log('compare',compare)
                
                const filtered = elementiz.filter(ele => ele.Cliente === compare);
                console.log('filtered',filtered)
                if (filtered.length > 0) {
                  console.log('cuay')
                  return false
                }else{
                  console.log('cefuay')
            
                  return true
                }
              }
            
              //quando currentcli cambia chiama changeUser
              useEffect(()=>{ 
                 // Carica i dati
                changeUser()
            
              },[currentCli])
      
                //se ce un utente predefinito carica i dati altrimenti prendi tutti
                useEffect(() => {
                  if (user !== null) {
                    console.log("Avvio caricamento dati utente");
                
                    if (user.DefaultCli && user.DefaultCli !== "") {
                      console.log("DefaultCli trovato:", user.DefaultCli);
                      const checked = check()
              
                      if(checked===true){
                        changeUser()
                      }else{
              
                        const compare = currentCli?currentCli:user.DefaultCli
              
                
                      const cachedData = sessionStorage.getItem(`interventiCliente_${compare}`);
                
                      if (cachedData) {
                        console.log("Dati pre-caricati trovati per", compare);
                        const data = JSON.parse(cachedData);
                        setInterventi(data.interventi)
                        setLTab(false)
      
                        setSedi(data.sedi)
                        setClienti(data.clienti)
                      } else {
                        console.log("Nessun dato pre-caricato. Scarico dal database...");
                        setLoading(true);
                        getInterventiCliente(db,user.Email,compare).then((data) => {
                          setInterventi(data.interventi)
                          setLTab(false)
        
                          setSedi(data.sedi)
                          setClienti(data.clienti)
                
                          // Salva nella cache
                          sessionStorage.setItem(`interventiCliente_${compare}`, JSON.stringify(data));
                        });
                      } }
                    } else {
                      console.log("Nessun DefaultCli, scarico tutti i dati");
                      getInterventi(db,user.Email,setSedi,setClienti,setInterventi)
                    }
                    
                  }
                }, [user]);


      /*useEffect(()=>{
        if(user!==null){
          if(user.DefaultCli&&user.DefaultCli!==''){
            if(listaI?.length>0&&listaSe?.length>0){
              setInterventi(listaI)
              setSedi(listaSe)
              setClienti(listaC)
            }else{
              setLTab(true)
              getInterventiCliente(db,user.Email,user.DefaultCli).then((data)=>{ 

                if(data){
                  setInterventi(data.interventi)
                  setLTab(false)

                  setSedi(data.sedi)
                  setClienti(data.clienti)
                  sessionStorage.setItem('interventi', JSON.stringify(data.interventi));
        sessionStorage.setItem('sedi', JSON.stringify(data.sedi));
        sessionStorage.setItem('clienti', JSON.stringify(data.clienti));
                }

              })
              
            }
          setClienteF(user.DefaultCli)
          }else{

         
          if(listaI!==null&&listaSe!==null){
    
          if(listaI.length>0&&listaSe.length>0){
            setInterventi(listaI)
            setSedi(listaSe)
            setClienti(listaC)
          }else{
            getInterventi(db,user.Email,setSedi,setClienti,setInterventi)
    
          }
        }else{
          getInterventi(db,user.Email,setSedi,setClienti,setInterventi)
    
        }
      }
      }

    
      },[user])*/

      useEffect(() => {
        isSottoInterventiAttivi();
      }, [interventi]); //use effect che serve per vedere se esistono sottointerventi

      function getNInt(){
        return sortedInts.filter((ele) => isConfirmedI(ele)).length;
  }


      function isConfirmedI(map){
        var titolo = map.Titolo?map.Titolo.toString().toLowerCase():''

        if(
        (statoF === "" ||
        statoF === "default" ||
          statoF === null ||
          map.Stato === statoF) &&
          (visualizeArchiviati === true||
            map.Archiviato === false)&&(soloRiparazione===false||(soloRiparazione === true &&
                map.SottoAttività === true))&&
                (findInput === "" ||findInput===null||
                (map.Titolo
                  ? map.Titolo.toLowerCase().includes(
                      findInput.toLowerCase()
                    )
                  : null) ||
                (map.Descrizione
                  ? map.Descrizione.toLowerCase().includes(
                      findInput.toLowerCase()
                    )
                  : null) ||
                (map.Oggetto
                  ? map.Oggetto.toLowerCase().includes(
                      findInput.toLowerCase()
                    )
                  : null) ||
                (map.Indirizzo
                  ? map.Indirizzo.toLowerCase().includes(
                      findInput.toLowerCase()
                    )
                  : null) ||
                (map.Data
                  ? map.Data.toLowerCase().includes(
                      findInput.toLowerCase()
                    )
                  : null)||
                  (map.SedeName
                    ? map.SedeName.toLowerCase().includes(
                        findInput.toLowerCase()
                      )
                    : null) ||
                  
                  (map.Sede
                    ? map.Sede.toLowerCase().includes(
                        findInput.toLowerCase()
                      )
                    : null))){
            return true
          }else{
            return false
          }
      } //interventi da visualizzare con i filtri

      const [sottoInterventiAttivi, setSottoInterventiAttivi] = useState(undefined); // key value

      function isSottoInterventiAttivi() {
        var aggiornata = new Object();
    
        //for(var i=0;i<activity.length;i++){
        interventi.forEach((ele, i) => {
          if (interventi[i].SottoAttività === true) {
            //var ele = activity[i];
            db.collection("Attività")
              .doc(ele.Id)
              .collection("Attività")
              .get()
              .then((sottoInterventi) => {
                var esito = false;
                for (var j = 0; j < sottoInterventi.docs.length; j++) {
                  if (sottoInterventi.docs[j].data().Stato !== "Conclusa") {
                    esito = true;
                  }
    
                  if (j == sottoInterventi.docs.length - 1) {
                    aggiornata[ele.key] = esito;
                    setSottoInterventiAttivi(aggiornata);
                  }
                }
              });
          }
        });
      } //funzione per verificare se ci sono sottointerventi attivi
      const [visualizeArchiviati, setVisualizeArchiviati] = useState(false); //var per visualizzare o meno gli interventi archiviati
      const [soloRiparazione,setSoloRiparazione] = useState(false)

      function reGet (){
        const compare = currentCli?currentCli:user.DefaultCli

        if(compare==='Tutti'){
          sessionStorage.removeItem(`interventi`);
    
        }else{
          sessionStorage.removeItem(`interventiCliente_${compare}`);
        }
        changeUser()

      }

      function getSubActs(interventoId) {
        return new Promise((resolve, reject) => {
            const acts = [];
            db.collection("Attività")
                .doc(interventoId)
                .collection('Attività')
                .onSnapshot((querySnapshot) => {
                    querySnapshot.forEach((act) => {
                        acts.push({
                            key: act.id,
                            ...act.data(),
                        });
                    });
                    resolve(acts);  // Risolve la promessa con i sottointerventi
                }, reject);  // Gestisce l'errore
        });
    }

      function handleTitoloClick(elemento) {
        setLoading(true)
        db.collection("Users")
          .doc(elemento.Op_Email)
          .get()
          .then((operatoreDoc) => {
            var operatoreData = operatoreDoc.data();
            console.log(operatoreDoc);
            var operatore = {
              Nome_Azienda: operatoreData.Nome_Azienda,
              Nome: operatoreData.Nome,
              Cognome: operatoreData.Cognome,
              Indirizzo: operatoreData.Indirizzo,
              Sede: operatoreData.Comune,
              Telefono: operatoreData.Numero,
              Email: operatoreData.Email
            };
            db.collection("Elementi").doc(elemento.Oggetto).get().then((elementoDoc) => {
              const elementiData = elementoDoc.exists ? [{
                  Nome: elementoDoc.data().Nome,
                  Esito: elemento.Esito,
              }] : [{
                  Nome: elemento.Oggetto,
                  Esito: elemento.Esito,
              }];

              // Recupera i sottointerventi
              getSubActs(elemento.Id).then((sottointerventi) => {
                  // Genera il PDF includendo i sottointerventi
                  console.log(sottointerventi)
                  GenerazionePdf.generaPdfIntervento(elemento, operatore, elementiData, sottointerventi,setLoading);
              });
          });
            
          });
      } //scarica il pdf riassuntivo dell intervento
      function archivia(ele, index, n) {
        if (n === 0) {
          db.collection("Attività")
            .doc(ele.Id)
            .update({
              Archiviato: true,
            })
            .then(() => {
              var arr = [...interventi];
              arr[index].Archiviato = true;
              setInterventi(arr);
              sessionStorage.setItem('interventi', JSON.stringify(interventi));

              alert(
                "Intervento archiviato, abilitare la visualizzazione degli interventi archiviati per visualizzarlo nuovamente."
              );
            });
        } else {
          db.collection("Attività")
            .doc(ele.Id)
            .update({
              Archiviato: false,
            })
            .then(() => {
              var arr = [...interventi];
              sessionStorage.setItem('interventi', JSON.stringify(interventi));
              arr[index].Archiviato = false;
              setInterventi(arr);
              alert(
                "Intervento rimosso dagli archiviati, ora sarà sempre visibile nella lista degli interventi."
              );
            });
        }
      } //funzione per archiviare gli interventi conclusi
      function openPopSott(info) {
        setInfoSub(info);
        setTriggerSub(true);
      } //funzione per aprire i sotto interventi

      function deleteIntervento(intervento,n){
        if(window.confirm("Sei sicuro di voler eliminare l'intervento: "+intervento.Titolo+"?"))
        //elimina da cartella cliente
        db.collection('Users').doc(intervento.Cliente).collection('Sedi').doc(intervento.Sede).collection('Attività').doc(intervento.Id).delete().then(()=>{
          //elimina da cartella off
          db.collection('Users').doc(intervento.Op_Email).collection('Clienti').doc(intervento.Cliente).collection('Attività').doc(intervento.Id).delete().then(()=>{
            //decrementa da cli su sede
            db.collection('Users').doc(intervento.Cliente).collection('Sedi').doc(intervento.Sede).update({
              Int: firebase.firestore.FieldValue.increment(-1)
            }).then(()=>{
                          //decrementa da cli
              db.collection('Users').doc(intervento.Cliente).update({
                Current_Int: firebase.firestore.FieldValue.increment(-1)
              }).then(()=>{
                db.collection('Users').doc(intervento.Op_Email).update({
                  Current_Int: firebase.firestore.FieldValue.increment(-1)
                })
                //elimina allegati da storage se presenti
                if(intervento.Allegati){
                  if(intervento.Allegati.length>0){
                    intervento.Allegati.forEach((all)=>{
                      var deleteRef = storageRef.child(all.StorageRef);
                      deleteRef.delete()
                    })
                  }

                }
                if(intervento.Macrocategoria!==''&&intervento.Oggetto!==''){
                  db.collection('Users').doc(intervento.Cliente).collection('Sedi').doc(intervento.Sede).collection('Categorie').doc(intervento.Categoria).collection('Sottocategorie')
                  .doc(intervento.Macrocategoria).collection('Elementi').doc(intervento.Oggetto).get().then((ele)=>{
                    if(ele.exists){
                      db.collection('Users').doc(intervento.Cliente).collection('Sedi').doc(intervento.Sede).collection('Categorie').doc(intervento.Categoria).collection('Sottocategorie')
                  .doc(intervento.Macrocategoria).collection('Elementi').doc(intervento.Oggetto).collection('Interventi').doc(intervento.Id).delete()
                    }
                  })
                }
                //elimina da cartella attivita
                db.collection('Attività').doc(intervento.Id).delete().then(()=>{
                  var arr =[...interventi]
                  arr.splice(n,1)
                  setInterventi(arr)
                  sessionStorage.setItem('interventi', JSON.stringify(arr));

                  alert('Intervento eliminato.')
                })
              })
            })
          })
        })
      }

      const onSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction });
      };
    
      const sortedInts = React.useMemo(() => {
        let sortableItems = [...interventi];
        if (sortConfig.key !== null) {
          sortableItems.sort((a, b) => {
            if (typeof a[sortConfig.key] === 'string') {
              return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else if (sortConfig.key === 'date') {
              return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            }else if (sortConfig.key === 'number') {
              return (a[sortConfig.key] - b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else {
              return 0;
            }
          });
        }
        return sortableItems;
      }, [interventi, sortConfig]);


  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
            {loading===true?<LoadingScreen text={'Generazione PDF in corso, attendere prego...'}/>:null}

         {user!==null&&user!==null?<Notifiche user={user}/>:null}
            <NavBar2/>
      {triggerSub === true ? (
        <SubActivity
          info={infoSub}
          acts={interventi}
          setActivity={setInterventi}
          sede={infoSub.Sede}
          trigger={triggerSub}
          setTriggerPop={setTriggerSub}
          tipo={user.Tipo}
          getInfo={user}
          reGet={reGet}
        />
      ) : null}
        <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
        <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>INTERVENTI</h4>
       
      </div>
      <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'75vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'7vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
            {getNInt()}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Interventi totali'}
          </h4>
          </div>
          <Tooltip
                    title={
                      visualizeArchiviati === false
                        ? "Visualizza interventi archiviati"
                        : "Nascondi interventi archiviati"
                    }
                  >
                    <IconButton
                      onClick={() =>
                        setVisualizeArchiviati(!visualizeArchiviati)
                      }
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                        backgroundColor:
                          visualizeArchiviati === false
                            ? "lightgrey"
                            : "lightskyblue",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft:5
                      }}
                    >
                      <ArchiveIcon
                        style={{
                          color:
                            visualizeArchiviati === false
                              ? "#5C6672"
                              : "#0496ff",
                              width:'1.2vw',
                              height:'1.2vw'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      soloRiparazione === false
                        ? "Solo con interventi di riparazione"
                        : "Tutti gli interventi"
                    }
                  >
                    <IconButton
                      onClick={() =>
                        setSoloRiparazione(!soloRiparazione)
                      }
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                        backgroundColor:
                          soloRiparazione === false
                            ? "lightgrey"
                            : "coral",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft:5
                      }}
                    >
                      <WarningIcon
                                          style={{
                                            color:
                            soloRiparazione === false
                              ? "#5C6672"
                              : "red",
                              width:'1.2vw',
                              height:'1.2vw'
                                          }}
                                        />
                    
                    </IconButton>
                  </Tooltip>
             {/* */}
              <select
                className='input-style'
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={statoF}
                onChange={(e) => {
                  setStatoF(e.target.value);
                  setClienteF(null);
                }}
                name="categoria"
              >
                <option value={"default"} disabled>
                  Seleziona stato...
                </option>
                <option value={""}>Tutti</option>
                <option value='In attesa'>In attesa</option>
                <option value='Pianificata'>Pianificata</option>
                <option value='Conclusa'>Concluso</option>
                <option value='Scaduto'>Scaduto</option>
              </select>

              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())
                 }
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca intervento per nome, descrizione..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                 onClick={()=>reGet()}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
          <div style={{marginTop:10,borderRadius:5,width:'75vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={() => onSort('Titolo')}>
            <h4 className="header-table" >Titolo {sortConfig.key === 'Titolo' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
        
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Descrizione')}>
          <h4 className="header-table" >Descrizione {sortConfig.key === 'Descrizione' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Cliente')}>
          <h4 className="header-table" >Cliente {sortConfig.key === 'Nome_Azienda' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Sede')}>
          <h4 className="header-table" >Sede {sortConfig.key === 'Sede' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Data')}>
          <h4 className="header-table" >Data {sortConfig.key === 'Data' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Stato')}>
          <h4 className="header-table" >Stato {sortConfig.key === 'Stato' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderLeft:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Categoria')}>
          <h4 className="header-table" >Categoria {sortConfig.key === 'Categoria' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          </div>
       {  lTab===true?<LoadingTab/>: <div style={{overflowY:'auto',height:'75vh'}}>
          {sortedInts.length>0?sortedInts.map((ele,n)=>(
            isConfirmedI(ele)===true? <div key={n} className='table-row'  style={{boxShadow:ele.Sott_Attivi?ele.Sott_Attivi===true?'0 0 1rem #FF0060':'0 2rem 3rem rgba(132, 139, 200, 0.18)':'0 2rem 3rem rgba(132, 139, 200, 0.18)'}}>
            <div  style={{cursor:'pointer',borderRight:'1px solid lightgrey',width:'15vw',height:'6rem',display:'flex',alignItems:'center'}}>
              <div>
              <Tooltip title={ele.Titolo}>

                                      <h4 onClick={()=>history.push(`/interventimanutentore/intervento/${ele.Id}`)} className="medium-text" style={{whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'9vw',cursor:'pointer'}}>
                {ele.Titolo}
              </h4>
              </Tooltip>
                </div>
              
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <Tooltip title={ele.Descrizione}>

              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Descrizione!==''?ele.Descrizione:'Non inserita'}
              </h4>
           </Tooltip>
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
                <Tooltip title={ele.Cliente}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Nome_Azienda}
              </h4>
              </Tooltip>
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.SedeName?ele.SedeName:ele.Sede}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Data==='No'?'Nessuna':formatData(ele.Data)} {ele.Hour?' - '+ele.Hour:null}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.Stato==='Conclusa'?'Concluso':ele.Stato}
              </h4>
           
              </div>
            </div>
            <div style={{width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.Categoria}
              </h4>
           
              </div>
            </div>
            <div style={{width:'7vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{marginLeft:'auto',marginRight:'20px',width:'fit-content',}}>
              <div style={{display:'flex'}}>
               {/* */}
                                     
                                       <Tooltip title="Visualizza dettagli">
                                      <button onClick={()=>history.push(`/interventimanutentore/intervento/${ele.Id}`)}
                                        className="btnD"
                                        style={{cursor:'pointer',marginLeft:0}}
                                      >
                                        <VisibilityIcon style={{ color: "#192bc2" }} />
                                      </button>
                                      </Tooltip>
                                      <Tooltip title="Scarica PDF riepilogativo">
                                       <button onClick={()=>handleTitoloClick(ele)}
                                         className="btnD"
                                         style={{cursor:'pointer',marginLeft:0}}   
                                       >
                                         <PictureAsPdfIcon style={{ color: "#1B9C85" }} />
                                       </button>
                                       </Tooltip>
                                      
                                       </div>
                                       <div style={{display:'flex'}}>
                                       {checkDeleteS(user.Permissions)===true?<div style={{marginLeft:'auto'}}><Tooltip title="Elimina intervento">
                                         <IconButton
                                         onClick={()=>deleteIntervento(ele,n)}
                                         className="btnD"
                                         style={{cursor:'pointer',marginLeft:0}}
                                       >
                                         <DeleteIcon style={{ color: "red" }} />
                                       </IconButton>
                                       </Tooltip></div>:null}
                                       {ele.Stato === "Conclusa" ? (
                                 checkWriteS(user.Permissions)===true? <div style={{marginLeft:'auto'}}>
                                    {ele.Archiviato === true ? (
                                      <Tooltip
                                        title="Rimuovi da archiviati"
                                        style={{ width: "fit-content" }}
                                      >
                                        <IconButton onClick={()=>archivia(ele,n,1)}
                                        >
                                          <UnarchiveIcon />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title="Archivia"
                                        style={{ width: "fit-content" }}
                                      >
                                        <IconButton onClick={()=>archivia(ele,n,0)}
                                        >
                                          <ArchiveIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </div>:null
                                ) : null}
                                       {ele.SottoAttività === true ? (
                                  <div>
                                    <Tooltip
                                      title="Azioni correttive"
                                      style={{ width: "fit-content" }}
                                    >
                                      <IconButton onClick={()=>openPopSott(ele)}
                                      >
                                        <WarningIcon
                                          style={{
                                            color: ele.Sott_Attivi?ele.Sott_Attivi ===
                                              true?'red':"#677483":'#677483',
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                ) : null}
                                
                               
                                       </div>
           
              </div>
            </div>
  
            </div>:null
           
          )):null}
                  {user&&getNInt()===0?<h4 className='normal-text' style={{marginTop:'1rem'}}>Nessun intervento trovato.</h4>:null}
                  <div style={{height:'3vh'}}></div>

        </div>}
        </div>
    </div>
  )
}

export default InterventiOffritore