import React,{useEffect, useState} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import firebase from "firebase";
import { getUtente,formatData,checkWriteS,checkDeleteS } from '../EngineClienti/engineClienti';
import { getScadenze,getScadenzeCliente } from '../EngineOffritore/engineOffritore';
import LoopIcon from '@material-ui/icons/Loop';
import '../general.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import GenerazionePdf from '../GenerazionePdf';
import SubManutenzioni from '../Popups/SubManutenzioni';
import DeleteIcon from '@mui/icons-material/Delete';
import NavBar2 from '../NavBar/NavBar2';
import Notifiche from '../Notifiche/Notifiche';
import WarningIcon from '@mui/icons-material/Warning';
import LoadingScreen from '../LoadingScreen';
import LoadingTab from '../Caricamenti/LoadingTab';
import { useCurrentCli } from '../../CurrentCliContext';


const ScadenzeOffritore = ({history}) => {

      const { currentCli,updateCurrentCli } = useCurrentCli();  // Ottieni il cliente selezionato dal Context
  

    const [sedi,setSedi] = useState([]) //lista sedi
    const [scadenze,setScadenze] = useState([]) //lista interventi
    const [clienti,setClienti] = useState([]) //lista fornitori
    const [user,setUser] = useState(null) //info utente 
    const [triggerSub, setTriggerSub] = useState(false);//trigger popup sottoaccount
    const [infoSub, setInfoSub] = useState(); 

        ///////FILTRI///////

        const [clienteF,setClienteF] = useState('default')
        const [statoF,setStatoF] = useState('default')
        const [findInput,setFindInput] = useState('')

    const db = firebase.firestore()
    const auth = firebase.auth()
    const storage = firebase.storage();
    var storageRef = storage.ref();
    
    const listaS = JSON.parse(sessionStorage.getItem('scadenze'));
    const listaSe = JSON.parse(sessionStorage.getItem('sedi'));
    const listaC = JSON.parse(sessionStorage.getItem('clienti'));

            //sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [loading,setLoading] = useState(false)

  const [lTab,setLTab] = useState(false) //caricamento tabella

    const Buttons = () =>{

      return(
        <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
          <div>
          <h4 onClick={()=>history.push('/scadenzemanutentore/programmamanutenzione')}  className='text-buttons' >
                  PROGRAMMA MANUTENZIONE
                </h4>
                
          </div>
        </div>
      )
    }

    useEffect(() => {
      if(user!==null){
        if(user.Current_Scad){
      if(user.Current_Scad===scadenze.length){
        sessionStorage.setItem('scadenze', JSON.stringify(scadenze));
        sessionStorage.setItem('sedi', JSON.stringify(sedi));
        sessionStorage.setItem('clienti', JSON.stringify(clienti));

      }
    }
    }  
    }, [scadenze]);

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

       //prende dati nuovo utente singolo
              function changeUser(){
                if(user!==null){
             
                 setLTab(true)
            
                 const compare = currentCli?currentCli:user.DefaultCli
                        
                  if (compare === "Tutti") {
              
                    getScadenze(db,user.Email,setSedi,setClienti,setScadenze)
      
                    setLTab(false)
            
                  }else{
                  // Controlla se i dati del cliente sono già in sessionStorage
                  const cachedData = sessionStorage.getItem(`scadenzeCliente_${compare}`);
            
                  if (cachedData) {
                    const data = JSON.parse(cachedData);
                    setClienti(data.clienti);
                    setScadenze(data.scadenze)
                    setSedi(data.sedi)
                    setLTab(false)              
                  } else {
                    getScadenzeCliente(db,user.Email,compare).then((data) => {
                      setLoading(false);
                      setClienti(data.clienti);
                    setScadenze(data.scadenze)
                    setSedi(data.sedi)
                    setLTab(false)      
              
                      // Salva i dati nella cache per il cliente selezionato
                      sessionStorage.setItem(
                        `scadenzeCliente_${compare}`,
                        JSON.stringify(data)
                      );
                    });
                  }
                }
                }
              }
            
              //controllo se utente selezionato è quello attuale
              function check(){
                const elementiz= [...listaS?listaS:scadenze]
            
                const compare = currentCli?currentCli:user.DefaultCli
                
                const filtered = elementiz.filter(ele => ele.Cliente === compare);
                if (filtered.length > 0) {
                  return false
                }else{
            
                  return true
                }
              }
            
              //quando currentcli cambia chiama changeUser
              useEffect(()=>{ 
                 // Carica i dati
                changeUser()
            
              },[currentCli])
      
                //se ce un utente predefinito carica i dati altrimenti prendi tutti
                useEffect(() => {
                  if (user !== null) {
                
                    if (user.DefaultCli && user.DefaultCli !== "") {
                      const checked = check()
              
                      if(checked===true){
                        changeUser()
                      }else{
              
                        const compare = currentCli?currentCli:user.DefaultCli
              
                
                      const cachedData = sessionStorage.getItem(`scadenzeCliente_${compare}`);
                
                      if (cachedData) {
                        const data = JSON.parse(cachedData);
                        setClienti(data.clienti);
                setScadenze(data.scadenze)
                setSedi(data.sedi)

                      } else {
                        setLTab(true);
                        getScadenzeCliente(db,user.Email,compare).then((data) => {
                          setClienti(data.clienti);
                          setScadenze(data.scadenze)
                          setSedi(data.sedi)
                          setLTab(false)       
                
                          // Salva nella cache
                          sessionStorage.setItem(`scadenzeCliente_${compare}`, JSON.stringify(data));
                        });
                      } }
                    } else {
                      getScadenze(db,user.Email,setSedi,setClienti,setScadenze)
                    }
                    
                  }
                }, [user]);

     /* useEffect(()=>{
        if(user!==null){
          if(user.DefaultCli&&user.DefaultCli!==''){
            if(listaS?.length>0&&listaSe?.length>0){
              setScadenze(listaS)
              setSedi(listaSe)
              setClienti(listaC)
            }else{
              setLTab(true)
              getScadenzeCliente(db,user.Email,user.DefaultCli).then((data) => {
                                   
                setClienti(data.clienti);
                setScadenze(data.scadenze)
                setSedi(data.sedi)
                setLTab(false)                         

                sessionStorage.setItem('scadenze', JSON.stringify(data.scadenze));
                sessionStorage.setItem('sedi', JSON.stringify(data.sedi));
                sessionStorage.setItem('clienti', JSON.stringify(data.clienti));
 });
      
            }
          setClienteF(user.DefaultCli)
          }else{

          if(listaS!==null&&listaSe!==null){
    
          if(listaS.length>0&&listaSe.length>0){
            setScadenze(listaS)
            setSedi(listaSe)
            setClienti(listaC)
          }else{
            getScadenze(db,user.Email,setSedi,setClienti,setScadenze)
    
          }
        }else{
          getScadenze(db,user.Email,setSedi,setClienti,setScadenze)
    
        }
      }
            
    }
    
      },[user]) */

      useEffect(() => {
        isSottoManutenzioniAttive();
      }, [scadenze]); //use effect che serve per vedere se esistono sottointerventi

      
      function getNInt(){
        return sortedScad.filter((ele) => isConfirmedI(ele)).length;
  }

      function isConfirmedI(map){
        var titolo = map.Titolo?map.Titolo.toString().toLowerCase():''

        if((clienteF === "" ||clienteF === "default" || clienteF === null || map.Cliente === clienteF) &&
        (statoF === "" ||statoF === "default" ||
          statoF === null ||
          map.Stato === statoF) &&
          (visualizeArchiviati === true||
            map.Archiviato === false)&&(soloRiparazione===false||(soloRiparazione === true &&
                map.SottoAttività === true))&&
                (findInput === "" ||findInput===null||
                (map.Titolo
                  ? map.Titolo.toLowerCase().includes(
                      findInput.toLowerCase()
                    )
                  : null) ||
                (map.Descrizione
                  ? map.Descrizione.toLowerCase().includes(
                      findInput.toLowerCase()
                    )
                  : null) ||
                (map.Oggetto
                  ? map.Oggetto.toLowerCase().includes(
                      findInput.toLowerCase()
                    )
                  : null) ||
                (map.Indirizzo
                  ? map.Indirizzo.toLowerCase().includes(
                      findInput.toLowerCase()
                    )
                  : null) ||
                (map.Data
                  ? map.Data.toLowerCase().includes(
                      findInput.toLowerCase()
                    )
                  : null)||
                  (map.Sede
                    ? map.Sede.toLowerCase().includes(
                        findInput.toLowerCase()
                      )||
                      (map.SedeName
                        ? map.SedeName.toLowerCase().includes(
                            findInput.toLowerCase()
                          )
                        : null)
                    : null))){
            return true
          }else{
            return false
          }
      } //interventi da visualizzare con i filtri

      const [sottoManutenzioniAttive,setSottoManutenzioniAttive] = useState(undefined) // key value

      function isSottoManutenzioniAttive(){
        var aggiornata = new Object();
        scadenze.forEach((ele,i)=>{
          if(scadenze[i].SottoAttività===true){
            db.collection("Users")
            .doc(ele.Cliente)
            .collection("Sedi")
            .doc(ele.Sede_cli)
            .collection("Manutenzioni")
            .doc(ele.Id)
            .collection("Manutenzioni").get().then((sottoManutenzioni)=>{
              var esito = false;
              var counter = 0;
              for(var j=0;j<sottoManutenzioni.docs.length;j++){
                db.collection("Manutenzioni").doc(sottoManutenzioni.docs[j].data().ref).get().then((sottoManutenzione)=>{
                  counter++;
                  if(sottoManutenzione.data().Stato!=="Conclusa"){
                    esito = true;
                  }
                  //if(counter==sottoManutenzioni.docs.length-1){
                    aggiornata[ele.key] = esito;
                    setSottoManutenzioniAttive(aggiornata)
                  //}
                })
                
              }
            })
          }
        })
      } //sottomanutenzioni attive cliente
      const [visualizeArchiviati, setVisualizeArchiviati] = useState(false); //var per visualizzare o meno gli interventi archiviati
      const [soloRiparazione,setSoloRiparazione] = useState(false)

      function reGet (){
        const compare = currentCli?currentCli:user.DefaultCli

        if(compare==='Tutti'){
          sessionStorage.removeItem(`scadenze`);
    
        }else{
          sessionStorage.removeItem(`scadenzeCliente_${compare}`);
        }
        changeUser()

      }

      function getSubScadenze(scadId, cliente) {
        return new Promise((resolve, reject) => {
          const subScadenze = []; // Array per tutte le sottoscadenze
      
          db.collection("Users")
          .doc(user.Email)
          .collection("Clienti")
          .doc(cliente)
          .collection("Manutenzioni")
          .doc(scadId)
          .collection('Manutenzioni')
            .get()
            .then((querySnapshot) => {
              const promises = querySnapshot.docs.map((actz) => {
                return db.collection('Manutenzioni').doc(actz.data().ref).get().then((act) => {
                  const subScadenza = {
                    key: act.id,
                    Titolo: act.data().Titolo || '',
                    Sede: act.data().Sede || '',
                    Descrizione: act.data().Descrizione || '',
                    Nome: act.data().Nome || '',
                    Cognome: act.data().Cognome || '',
                    Priorità: act.data().Priorità || '',
                    Oggetto: act.data().Oggetto || '',
                    Stato: act.data().Stato || '',
                    Cliente: act.data().Cliente || '',
                    Sede_cli: act.data().Sede_cli || '',
                    Elementi: act.data().Elementi || [],
                    Report: act.data().Report || '',
                    Esito: act.data().Esito || '',
                    SottoAttività: act.data().SottoAttività ? act.data().SottoAttività : false,
                    Indirizzo: act.data().Indirizzo || '',
                    Nome_AziendaCli: act.data().Nome_AziendaCli || '',
                    Nome_AziendaOff: act.data().Nome_AziendaOff || '',
                    InterventiPath: act.data().InterventiPath || '',
                    Categoria: act.data().Categoria || '',
                    Macrocategoria: act.data().Macrocategoria || '',
                    Operatore: user.Tipo === 'Lavoratore' || user.TipoSott === 'Lavoratore'
                      ? (act.data().Operatore ? act.data().Operatore : user.Tipo === 'SottoAccount' ? user.MasterAccount : auth.currentUser.email)
                      : (act.data().Operatore ? act.data().Operatore : user.Fornitore),
                    Archiviato: act.data().Archiviato ? act.data().Archiviato : false,
                    Type: act.data().Type ? act.data().Type : null,
                  };
      
                  subScadenze.push(subScadenza);
                });
              });
      
              // Una volta completate tutte le promesse, risolviamo la promessa principale con i dati raccolti
              Promise.all(promises)
                .then(() => resolve(subScadenze))
                .catch(reject); // In caso di errore, lo gestiamo qui
            })
            .catch(reject); // Gestione dell'errore nel caso in cui il get fallisca
        });
      }

      async function handleManutenzionePdf(manutenzioneReal) {
        try {
          setLoading(true)
          const manutenzione = { ...manutenzioneReal }; // Clona l'oggetto per evitare mutazioni indesiderate
          // Recupera i dati dell'operatore
          const operatoreDoc = await db.collection("Users").doc(manutenzione.Operatore).get();
          if (!operatoreDoc.exists) {
            throw new Error(`Operatore con ID ${manutenzione.Operatore} non trovato.`);
          }
          const operatoreData = operatoreDoc.data();
          const operatore = {
            Nome_Azienda: operatoreData.Nome_Azienda || '',
            Nome: operatoreData.Nome || '',
            Cognome: operatoreData.Cognome || '',
            Indirizzo: operatoreData.Indirizzo || '',
            Sede: operatoreData.Comune || '',
            Telefono: operatoreData.Numero || '',
          };
      
          // Recupera i dati del cliente
          const clienteDoc = await db.collection("Users").doc(manutenzione.Cliente).get();
          if (!clienteDoc.exists) {
            throw new Error(`Cliente con ID ${manutenzione.Cliente} non trovato.`);
          }
          const clienteData = clienteDoc.data();
          manutenzione.Nome_AziendaCli = clienteData.Nome_Azienda || '';
          manutenzione.Numero_telefono_cli = clienteData.Numero || '';
          manutenzione.Sede_cli =  manutenzione.SedeName?manutenzione.SedeName!==''?manutenzione.SedeName:manutenzione.Sede:manutenzione.Sede || '';
      
          // Inizializza la lista degli elementi e delle sottoscadenze
          const listaElementi = [];
          const listaSottoscadenze = [];
      
          // Funzione per recuperare i dettagli di un elemento
          const getElementoDetails = async (elePath) => {
            const path = elePath.Elemento ? elePath.Elemento : elePath;
            const eleRef = await db.doc(path).get();
            if (!eleRef.exists) return null;
            const eleDataRef = await db.collection("Elementi").doc(eleRef.data().ref).get();
            if (!eleDataRef.exists) return null;
            return {
              Nome: eleDataRef.data().Nome || 'Nome non disponibile',
              Esito: elePath.Esito || 'Non specificato',
            };
          };
      
          // Recupera le sottoscadenze se SottoAttività è true
          if (manutenzione.SottoAttività) {
            const subScadenze = await getSubScadenze(manutenzione.Id, manutenzione.Cliente);
            // Recupera gli elementi per ciascuna sottoscadenza
            for (const sottoscadenza of subScadenze) {
              const elementiSotto = [];
              if (sottoscadenza.Elementi && sottoscadenza.Elementi.length > 0) {
                for (const ele of sottoscadenza.Elementi) {
                  const elementoDettagli = await getElementoDetails(ele);
                  if (elementoDettagli) {
                    elementiSotto.push(elementoDettagli);
                  }
                }
              }
      
              // Aggiungi la sottoscadenza alla lista
              listaSottoscadenze.push({
                ...sottoscadenza,
                Elementi: elementiSotto,
              });
            }
          }
      
          // Recupera gli elementi della manutenzione principale
          if (manutenzione.Oggetto && manutenzione.Oggetto.includes('Documento:')) {
            const toGet = manutenzione.Titolo.split(' ').slice(1).join(' ');
            manutenzione.Oggetto = toGet;
            listaElementi.push({
              Nome: toGet,
              Esito: manutenzione.Esito || 'Non specificato',
            });
          } else if (manutenzione.Elementi && manutenzione.Elementi.length > 0) {
            for (const ele of manutenzione.Elementi) {
              const elementoDettagli = await getElementoDetails(ele);
              if (elementoDettagli) {
                listaElementi.push(elementoDettagli);
              }
            }
          }
      
          // Genera il PDF passando tutte le informazioni necessarie
          GenerazionePdf.generaPdfManutenzione(
            manutenzione,
            operatore,
            listaElementi,
            listaSottoscadenze,
            setLoading
          );
        } catch (error) {
          console.error("Errore durante la generazione del PDF della manutenzione:", error);
          // Puoi gestire ulteriormente l'errore qui, ad esempio mostrando una notifica all'utente
        }
      } //funzione che genera la manutenzione pdf
      function archivia(ele, index, n) {
        if (n === 0) {
          db.collection("Manutenzioni")
            .doc(ele.Id)
            .update({
              Archiviato: true,
            })
            .then(() => {
              var arr = [...scadenze];
              arr[index].Archiviato = true;
              setScadenze(arr);
              sessionStorage.setItem('scadenze', JSON.stringify(scadenze));
              alert(
                "Scadenza archiviata, abilitare la visualizzazione delle scadenze archiviate per visualizzarla nuovamente."
              );
            });
        } else {
          db.collection("Manutenzioni")
            .doc(ele.Id)
            .update({
              Archiviato: false,
            })
            .then(() => {
              var arr = [...scadenze];
              sessionStorage.setItem('scadenze', JSON.stringify(scadenze));
              arr[index].Archiviato = false;
              setScadenze(arr);
              alert(
                "Scadenza rimossa dagli archiviati, ora sarà sempre visibile nella lista delle scadenze."
              );
            });
        }
      } //funzione per archiviare gli interventi conclusi
      function openPopSott(info) {
        setInfoSub(info);
        setTriggerSub(true);
      } //funzione per aprire i sotto interventi
      const onSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction });
      };
      async function deleteScadenza(scad,n){
        if(window.confirm("Sei sicuro di voler eliminare la scadenza: "+scad.Titolo+"?")){
          try{
          
          // Funzione per recuperare i dettagli di un elemento
          const deleteElemento= async (elePath) => {
            const path = elePath.Elemento ? elePath.Elemento : elePath;
            const eleRef = await db.doc(path).get();
            if (!eleRef.exists) return null;
            db.doc(path).collection('Manutenzioni').doc(scad.Id).get().then((manutenzione)=>{
              if(manutenzione.exists){
                                //elimina ref della manutenzione sull elemento
                db.doc(path).collection('Manutenzioni').doc(scad.Id).delete().then(()=>{
                                  //decrementa numero manutenzioni all elemento
                  db.doc(path).update({
                    NumeroManutenzioni: firebase.firestore.FieldValue.increment(-1)
                  })
                })
              }
            })
          };
          if(scad.Oggetto && scad.Oggetto.includes('Documento:')){
            if(scad.DocId){

              db.collection('Documenti').doc(scad.DocId).update({
                Scadenza: 'No'
              })

            }else{
              alert('Documento non trovato, eliminare la scadenza del documento manualmente dalla pagina Documenti.')
              return
            }
          }else{
            for (const ele of scad.Elementi) {
              await deleteElemento(ele);
             
           }
          }
         
        
          //elimina annotazione a cliente
           db.collection('Users').doc(scad.Cliente).collection('Annotazioni').doc(scad.Id).delete().then(()=>{
            //elimina annotazione a man
            db.collection('Users').doc(scad.Operatore).collection('Annotazioni').doc(scad.Id).delete().then(()=>{
              //elimina manutenzione da cliente
              db.collection('Users').doc(scad.Cliente).collection('Sedi').doc(scad.Sede).collection('Manutenzioni').doc(scad.Id).delete().then(()=>{
                //elimina manutenzione da man
                db.collection('Users').doc(scad.Operatore).collection('Clienti').doc(scad.Cliente).collection('Manutenzioni').doc(scad.Id).delete().then(()=>{
                  //aggiorna n man a man
                  db.collection('Users').doc(scad.Operatore).collection('Clienti').doc(scad.Cliente).update({
                    Man: firebase.firestore.FieldValue.increment(-1)
                  }).then(()=>{
                    db.collection('Users').doc(scad.Operatore).update({
                      Current_Scad: firebase.firestore.FieldValue.increment(-1)
                    }).then(()=>{
                      //aggiorna n man a cliente
                      db.collection('Users').doc(scad.Cliente).update({
                        Current_Scad: firebase.firestore.FieldValue.increment(-1)
                      }).then(()=>{
                        db.collection('Users').doc(scad.Cliente).collection('Sedi').doc(scad.Sede).update({
                          Man: firebase.firestore.FieldValue.increment(-1)
                        }).then(()=>{
                          db.collection('Manutenzioni').doc(scad.Id).delete().then(()=>{
 //aggiorna lista nuova
 var arr = [...scadenze]
 arr.splice(n, 1)
 setScadenze(arr)
 sessionStorage.setItem('scadenze', JSON.stringify(arr));
 alert('Scadenza eliminata con successo!')
                          })
                         
                        })
                      })
                    })
                  })
                })
              })
            })
           })
          }catch(e){
            alert('Errore: '+e)
          }
          }
      }
    
      const sortedScad = React.useMemo(() => {
        let sortableItems = [...scadenze];
        if (sortConfig.key !== null) {
          sortableItems.sort((a, b) => {
            if (typeof a[sortConfig.key] === 'string') {
              return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else if (sortConfig.key === 'date') {
              return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            }else if (sortConfig.key === 'number') {
              return (a[sortConfig.key] - b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else {
              return 0;
            }
          });
        }
        return sortableItems;
      }, [scadenze, sortConfig]);

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
            {loading===true?<LoadingScreen text={'Generazione PDF in corso, attendere prego...'}/>:null}

         {user!==null&&user!==null?<Notifiche user={user}/>:null}
            <NavBar2/>
      {triggerSub === true ? (
        <SubManutenzioni
          info={infoSub}
          acts={scadenze}
          setActivity={setScadenze}
          sede={infoSub.Sede}
          trigger={triggerSub}
          setTriggerPop={setTriggerSub}
          tipo={user.Tipo}
          email={user.Email}
          getInfo={user}
        />
      ) : null}
        <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
        <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>SCADENZE</h4>
       
      </div>
      {user!==null?checkWriteS(user.Permissions)===true?<Buttons/>:null:null}
      <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'75vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'7vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {getNInt()}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Scadenze totali'}
          </h4>
          </div>
          <Tooltip
                    title={
                      visualizeArchiviati === false
                        ? "Visualizza interventi archiviati"
                        : "Nascondi interventi archiviati"
                    }
                  >
                    <IconButton
                      onClick={() =>
                        setVisualizeArchiviati(!visualizeArchiviati)
                      }
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                        backgroundColor:
                          visualizeArchiviati === false
                            ? "lightgrey"
                            : "lightskyblue",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft:5
                      }}
                    >
                      <ArchiveIcon
                        style={{
                          color:
                            visualizeArchiviati === false
                              ? "#5C6672"
                              : "#0496ff",
                              width:'1.2vw',
                              height:'1.2vw'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      soloRiparazione === false
                        ? "Solo con azioni correttive"
                        : "Tutte le scadenze"
                    }
                  >
                    <IconButton
                      onClick={() =>
                        setSoloRiparazione(!soloRiparazione)
                      }
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                        backgroundColor:
                          soloRiparazione === false
                            ? "lightgrey"
                            : "coral",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft:5
                      }}
                    >
                      <WarningIcon
                                          style={{
                                            color:
                            soloRiparazione === false
                              ? "#5C6672"
                              : "red",
                              width:'1.2vw',
                              height:'1.2vw'
                                          }}
                                        />
                    
                    </IconButton>
                  </Tooltip>
             {/* */}
              <select
                className='input-style'
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={statoF}
                onChange={(e) => {
                  setStatoF(e.target.value);
                  setClienteF('default');
                }}
                name="categoria"
              >
                <option value={"default"} disabled>
                  Seleziona stato...
                </option>
                <option value={""}>Tutti</option>
                <option value='In attesa'>In attesa</option>
                <option value='Pianificata'>Pianificata</option>
                <option value='Conclusa'>Concluso</option>
                <option value='Scaduto'>Scaduto</option>
              </select>
       
              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())
                 }
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca scadenza per nome, descrizione o sede..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                 onClick={()=>reGet()}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
          <div style={{marginTop:10,borderRadius:5,width:'75vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={() => onSort('Titolo')}>
            <h4 className="header-table" >Titolo {sortConfig.key === 'Titolo' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
        
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Descrizione')}>
          <h4 className="header-table" >Descrizione {sortConfig.key === 'Descrizione' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Cliente')}>
          <h4 className="header-table" >Cliente {sortConfig.key === 'Nome_Azienda' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Sede')}>
          <h4 className="header-table" >Sede {sortConfig.key === 'Sede' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Data')}>
          <h4 className="header-table" >Data {sortConfig.key === 'Data' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Stato')}>
          <h4 className="header-table" >Stato {sortConfig.key === 'Stato' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderLeft:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Categoria')}>
          <h4 className="header-table" >Categoria {sortConfig.key === 'Categoria' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          </div>
         {lTab===true?<LoadingTab/>: <div style={{overflowY:'auto',height:'74vh'}}>
          {sortedScad.length>0?sortedScad.map((ele,n)=>(
            isConfirmedI(ele)===true? <div key={n} className='table-row' style={{boxShadow:ele.Sott_Attivi?ele.Sott_Attivi===true?'0 0 1rem #FF0060':'0 2rem 3rem rgba(132, 139, 200, 0.18)':'0 2rem 3rem rgba(132, 139, 200, 0.18)'}}>
            <div  style={{cursor:'pointer',borderRight:'1px solid lightgrey',width:'15vw',height:'6rem',display:'flex',alignItems:'center'}}>
              <div>
              <Tooltip title={ele.Titolo}>

                                      <h4 onClick={()=>history.push(`/scadenzemanutentore/scadenza/${ele.Id}`)} className="medium-text" style={{whiteSpace: 'nowrap',color:ele.Sott_Attivi===true?'red':'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'9vw',cursor:'pointer'}}>
                {ele.Titolo}
              </h4>
              </Tooltip>
                </div>
              
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <Tooltip title={ele.Descrizione}>

              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Descrizione!==''?ele.Descrizione:'Non inserita'}
              </h4>
           </Tooltip>
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
                <Tooltip title={ele.Cliente}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Nome_AziendaCli}
              </h4>
              </Tooltip>
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.SedeName?ele.SedeName:ele.Sede}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Data==='No'?'Nessuna':formatData(ele.Data)}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.Stato==='Conclusa'?'Concluso':ele.Stato}
              </h4>
              {ele.Stato==='Conclusa'&&ele.Esito==='Positivo'?<h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              Esito: Positivo
              </h4>:null}
              </div>
            </div>
            <div style={{width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.Categoria}
              </h4>
           
              </div>
            </div>
            <div style={{width:'7vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{marginLeft:'auto',marginRight:'20px',width:'fit-content',}}>
              <div style={{display:'flex'}}>
               {/* */}
                                     
                                       <Tooltip title="Visualizza dettagli">
                                      <button onClick={()=>history.push(`/scadenzemanutentore/scadenza/${ele.Id}`)}
                                        className="btnD"
                                        style={{cursor:'pointer',marginLeft:0}}
                                      >
                                        <VisibilityIcon style={{ color: "#192bc2" }} />
                                      </button>
                                      </Tooltip>
                                      {checkDeleteS(user.Permissions)===true?<div style={{marginLeft:'auto'}}><Tooltip title="Elimina intervento">
                                         <IconButton
                                         onClick={()=>deleteScadenza(ele,n)}
                                         className="btnD"
                                         style={{cursor:'pointer',marginLeft:0}}
                                       >
                                         <DeleteIcon style={{ color: "red" }} />
                                       </IconButton>
                                       </Tooltip></div>:null}
                                      <Tooltip title="Scarica PDF riepilogativo">
                                       <button onClick={()=>handleManutenzionePdf(ele)}
                                         className="btnD"
                                         style={{cursor:'pointer',marginLeft:0}}   
                                       >
                                         <PictureAsPdfIcon style={{ color: "#1B9C85" }} />
                                       </button>
                                       </Tooltip>
                                      
                                       </div>
                                       <div style={{display:'flex'}}>
                                      
                                       {ele.Stato === "Conclusa" ? (
                                  <div style={{marginLeft:'auto'}}>
                                    {ele.Archiviato === true ? (
                                      <Tooltip
                                        title="Rimuovi da archiviati"
                                        style={{ width: "fit-content" }}
                                      >
                                        <IconButton onClick={()=>archivia(ele,n,1)}
                                        >
                                          <UnarchiveIcon />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title="Archivia"
                                        style={{ width: "fit-content" }}
                                      >
                                        <IconButton onClick={()=>archivia(ele,n,0)}
                                        >
                                          <ArchiveIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </div>
                                ) : null}
                                       {ele.SottoAttività === true ? (
                                  <div>
                                    <Tooltip
                                      title="Azioni correttive"
                                      style={{ width: "fit-content" }}
                                    >
                                      <IconButton onClick={()=> history.push(`/scadenzemanutentore/scadenza/${ele.Id}?section=6`)}
                                      >
                                        <WarningIcon
                                          style={{
                                            color: ele.Sott_Attivi?ele.Sott_Attivi ===
                                              true?'red':"#677483":'#677483',
                                          }}
                                        />

                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                ) : null}
                                       </div>
           
              </div>
            </div>
  
            </div>:null
           
          )): null}
          {user&&getNInt()===0?<h4 className='normal-text' style={{marginTop:'1rem'}}>Nessuna scadenza trovata.</h4>:null}
                                                    <div style={{height:'5vh'}}></div>

        </div>}
        </div>
    </div>
  )
}

export default ScadenzeOffritore