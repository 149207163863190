import React , { useState, useEffect } from 'react'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,checkWriteS,} from "../../EngineClienti/engineClienti";
import Loading from '../../loading';
import CancelIcon from '@material-ui/icons/Cancel';
import { getClientiSediCategorie,getCartelle } from "../../EngineOffritore/engineOffritore";
import NavBar2 from '../../NavBar/NavBar2';
import { getSedeName } from '../../EngineClienti/engineClienti';
const EliminaCartelle = ({history}) => {

    const auth = firebase.auth();
    const db = firebase.firestore();
    const storage = firebase.storage();
    var storageRef = storage.ref();

    const [added,setAdded] = useState([]) //documenti aggiunti
    const [userM,setUserM] = useState(null)
    const [categorie, setCategorie] = useState([]); //lista categorie
    const [cartelle, setCartelle] = useState([]); //lista cartelle

    const [sedi,setSedi] = useState([])
    const [clienti,setClienti] = useState([])


    const [selectedCliente,setSelectedCliente] = useState('default')
    const [selectedSede,setSelectedSede] = useState('default')
    const [selectedCategoria,setSelectedCategoria] = useState('default')
    const [selectedCartella,setSelectedCartella] = useState('default')

    const [percentage,setPercentage] = useState(0)


    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUserM)
          }
        })
      },[auth])
    
      useEffect(()=>{
        if(userM!==null){
         getClientiSediCategorie(db,userM.Email,setClienti,setSedi,setCategorie)      
      }
    
      },[userM])

      useEffect(()=>{
        setSelectedSede('default')
       },[selectedCliente])

      useEffect(()=>{
       setSelectedCategoria('default')
      },[selectedSede])

      useEffect(()=>{
        if(selectedCategoria!==''&&selectedCategoria!=='default'){
          setSelectedCartella('default')
          getCartelle(db,selectedCliente,selectedSede,selectedCategoria,setCartelle)
        }
      },[selectedCategoria])

      useEffect(()=>{
       setSelectedCategoria('default')
      },[selectedSede])

 

      function deleteCartella(){
      const selectedCartellaObj = cartelle.find(cart => cart.Id === selectedCartella);
      if (selectedCartellaObj) {
        const docValue = selectedCartellaObj.Doc;
        if(docValue===0){
          db.collection("Users").doc(selectedCliente).collection("Sedi").doc(selectedSede).collection("Categorie").doc(selectedCategoria).collection("Cartelle").doc(selectedCartella).delete().then(() => {
            alert("Cartella eliminata con successo!");
            setSelectedCartella("default");
            setSelectedCategoria('default')
            setSelectedSede('default')
          })
        }else{
          alert("La cartella non può essere eliminata poichè contiene documenti.")
        }
        console.log(docValue);
      } else {
        console.log("Cartella non trovata o senza documenti.",selectedCartella,selectedCartellaObj);
      }
      }


  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
      <NavBar2/>
    <div
   style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}
 >
    <div  style={{  width: "50vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>ELIMINA CARTELLE </h4>
          <h4 className="sub-text" style={{}}>Potranno essere eliminate solamente le cartelle senza documenti al suo interno.</h4>
          </div>
        </div>
        <div style={{display:'flex'}}>
        <div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'50rem'}}>

        <h4 className="sub-text-b" style={{}}>Seleziona il cliente a cui desideri eliminare la cartella:</h4>
        <select className="input-style"
        defaultValue={"default"}
        value={selectedCliente} onChange={(e) => {
            setSelectedCliente(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona cliente...
                </option>
                {clienti && clienti.length > 0 && clienti.map((cliente,n)=>{
                                  return <option key={n} value={cliente.Email}>{cliente.Nome_Azienda}</option>
                              })}
        </select>
                     {/*SELEZIONE SEDE */}

        {selectedCliente!=='default'?<div style={{marginTop:'1rem'}}>
        <h4  className="sub-text-b">Seleziona sede:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selectedSede} onChange={(e) => {
            setSelectedSede(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                {sedi && sedi.length > 0 && sedi.map((sede,n)=>{
                                   if(sede.Cliente===selectedCliente){

                                    return <option key={n} value={sede.Id}>{sede.Nome?sede.Nome!==''?sede.Nome:sede.Comune:sede.Comune}</option>
                                      }else{
                                        return null
                                      }
                              })}
        </select></div>:null 
        }
{/*SELEZIONE CATEGORIA */}

{selectedSede!=='default'?<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona categoria:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selectedCategoria} onChange={(e) => {
            setSelectedCategoria(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                {categorie && categorie.length > 0 && categorie.map((cat,n)=>{if(cat.Sede===selectedSede&&cat.Cliente===selectedCliente){
                                  return <option key={n} value={cat.Id}>{cat.Nome}</option>
                }else{
                  return null
                }
                              })}
        </select></div>:null}
        {/*SELEZIONE CARTELLA */}
    
{selectedCategoria!==''&&selectedCategoria!=='default'?<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona cartella:</h4>
<div style={{display:'flex',alignItems:'center'}}>
        <select className="input-style2" style={{width:'15rem',fontWeight:'normal'}} defaultValue={"default"}
        value={selectedCartella} onChange={(e) => {
            setSelectedCartella(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona cartella...
                </option>
                {cartelle && cartelle.length > 0 && cartelle.map((cart,n)=>(
                                 <option key={n} value={cart.Id}>{cart.Nome}</option>
                )
                              )}
        </select>
        
        </div></div>:null}

        {selectedCartella!=='default'&&<button className='main-button' style={{marginTop:'2vh',backgroundColor:'#e01e37'}} onClick={()=>deleteCartella()}>ELIMINA CARTELLA</button>}

        </div>
     
        </div>

    </div>
 </div>
  )
}

export default EliminaCartelle