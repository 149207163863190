import React,{useEffect, useState, useMemo} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Icon, IconButton, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import firebase from "firebase";
import { getElementi, getUtente,getIcon,checkDeleteS,checkWriteS, getSedeName, isShared,formatData } from '../EngineClienti/engineClienti';
import LoopIcon from '@material-ui/icons/Loop';
import '../general.css'
import { useHistory } from "react-router-dom";
import Condividi from '../Popups/Condividi';
import Loading from '../loading';
import ExcelMenu from './Popups/ExcelMenu';
import * as XLSX from "xlsx/xlsx.mjs";
import DeleteIcon from '@mui/icons-material/Delete';
import Notifiche from '../Notifiche/Notifiche'
import NavBar from '../NavBar/NavBar'
import GridOnIcon from '@mui/icons-material/GridOn';
import { FixedSizeList as List } from 'react-window';
import HelpIcon from '@mui/icons-material/Help';
import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisher';

const Elementi = () => {



  const [elementi,setElementi] = useState([]) //lista elementi
  const [categorie,setCategorie] = useState([]) //lista categorie
  const [sedi,setSedi] = useState([]) //lista sedi
  const [sottocategorie,setSottoCategorie] = useState([]) //lista sottocategorie
  const [user,setUser] = useState(null) //info utente 

  ////filtri

  const [sedeF,setSedeF] = useState('default')
  const [categoriaF,setCategoriaF] = useState('default')
  const [sottocategoriaF,setSottoCategoriaF] = useState('default')
  const [findInput,setFindInput] = useState('')

  //// trigger popups

  const [triggerCond,setTriggerCond] = useState(false) //condivisione multipla
  const [openExcel,setOpenExcel] = useState(false) //apri menu excel

  const db = firebase.firestore()
  const auth = firebase.auth()
  const history = useHistory()
  const listaE = JSON.parse(sessionStorage.getItem('elementi'));
  const listaC = JSON.parse(sessionStorage.getItem('categorie'));
  const listaS = JSON.parse(sessionStorage.getItem('sottocategorie'));
  const listaSe = JSON.parse(sessionStorage.getItem('sedi'));
  //sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [isEleBuilder,setIsEleBuilder] = useState(0)

  const [mapList,setMapList] = useState([])



  useEffect(() => {
    if(user!==null){
    if(user.Current_Ele===elementi.length){
      sessionStorage.setItem('elementi', JSON.stringify(elementi));
      sessionStorage.setItem('categorie', JSON.stringify(categorie));
      sessionStorage.setItem('sottocategorie', JSON.stringify(sottocategorie));
      sessionStorage.setItem('sedi', JSON.stringify(sedi));
      getMappaNames(elementi);
    }
  }
  }, [elementi]);


  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getUtente(db,auth,setUser)
      }
    })
  },[auth])

  useEffect(()=>{
    if(user!==null){
     
      if(listaE!==null&&listaC!==null&&listaS!==null){

      if(listaE.length>0&&listaC.length>0&&listaS.length>0&&listaSe.length>0){
        setCategorie(listaC)
        setElementi(listaE)
        setSottoCategorie(listaS)
        setSedi(listaSe)
      }else{
        getElementi(db,user.Email,setSedi,setCategorie,setSottoCategorie,setElementi)

      }
    }else{
      getElementi(db,user.Email,setSedi,setCategorie,setSottoCategorie,setElementi)

    }
  }

  },[user])



  const Buttons = () =>{

    return(
      <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
      {checkWriteS(user.Permissions)===true?<div>
      <h4 onClick={()=>history.push('/elementi/aggiungicategoria')}  className='text-buttons' >
              AGGIUNGI CATEGORIA
            </h4>
      </div>:null}
      {checkWriteS(user.Permissions)===true?<div>
        <h4 onClick={()=>history.push('/elementi/aggiungielemento')}  className='text-buttons' >
                AGGIUNGI ELEMENTO
              </h4>
              
        </div>:null}
        {checkWriteS(user.Permissions)===true?<div>
        <h4 onClick={()=>setTriggerCond(true)}  className='text-buttons'  >
                CONDIVISIONE MULTIPLA
              </h4>
              
        </div>:null}
        <div>
        <h4 onClick={()=>setOpenExcel(true)}  className='text-buttons2' style={{color:'#1B9C85',borderBottom:'1px solid #1B9C85'}} >
                MENÙ EXCEL
              </h4>
              
        </div>
      </div>
    )
  }
  function reGet (){
    sessionStorage.clear()
    getElementi(db,user.Email,setSedi,setCategorie,setSottoCategorie,setElementi)

  }
  function importExcel(file) {
    var fileName = "";
    var arr = [];
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];
        fileName = wsname;
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      if (d.length > 0) {
        d.forEach((ele) => {

          var f = elementi.some((elez) => elez.Id.trim() === ele.ID_TESTUALE.trim());
          if (f === false) {
            arr.push(ele);
          }
        });

        db.collection("Users")
          .doc(user.Email)
          .get()
          .then((userx) => {
            const tot =
              parseInt(userx.data().Current_Ele, 10) + parseInt(arr.length, 10);
            if (tot <= userx.data().Max_Ele) {
              if (
                window.confirm("Vuoi aggiungere " + arr.length + " elementi?")
              ) {
                db.collection("Users").doc(user.Email).update({
                  Current_Ele: tot,
                });
                var elementiCategorie = [];
                var altriCategorie = [];
                //var elementiGia = []; // mi serve per sapere quanti valori hanno già le macrocategorie dato che quella merda di firebase nelle transaction ti costringe a leggere prima di scrivere

                var batch = db.batch();
                var str = "";
                var cont = 0;
                arr.forEach((elemen) => {
                  cont = cont + 1;
                  //controllo esistenza categoria
                  db.collection("Users")
                    .doc(user.Email)
                    .collection("Sedi")
                    .doc(elemen.Sede)
                    .collection("Categorie")
                    .doc(elemen.Categoria.trim())
                    .get()
                    .then((catx) => {
                      if (catx.exists) {
                        db.collection("Users")
                          .doc(user.Email)
                          .collection("Sedi")
                          .doc(elemen.Sede)
                          .collection("Categorie")
                          .doc(elemen.Categoria.trim())
                          .update({
                            Ele: firebase.firestore.FieldValue.increment(1),
                          }); //aumenta ele categoria
                      } else {
                        db.collection("Users")
                          .doc(user.Email)
                          .collection("Sedi")
                          .doc(elemen.Sede)
                          .collection("Categorie")
                          .doc(elemen.Categoria.trim())
                          .set({
                            Nome: elemen.Categoria.toString().trim(),
                            Sede: elemen.Sede,
                            Doc: 0,
                            Ele: 0,
                          })
                          .then(() => {
                            db.collection("Users")
                              .doc(user.Email)
                              .collection("Sedi")
                              .doc(elemen.Sede)
                              .collection("Categorie")
                              .doc(elemen.Categoria.trim())
                              .update({
                                Ele: firebase.firestore.FieldValue.increment(1),
                              }); //aumenta ele categoria
                          });
                      }
                    })
                    .then(() => {
                      //controllo esistenza macrocategoria
                      db.collection("Users")
                      .doc(user.Email)
                      .collection("Sedi")
                      .doc(elemen.Sede)
                        .collection("Categorie")
                        .doc(elemen.Categoria.trim())
                        .collection("Sottocategorie")
                        .doc(elemen.Sottocategoria.trim())
                        .get()
                        .then((macrx) => {
                          if (macrx.exists) {
                            db.collection("Users")
                            .doc(user.Email)
                            .collection("Sedi")
                            .doc(elemen.Sede)
                              .collection("Categorie")
                              .doc(elemen.Categoria.trim())
                              .collection("Sottocategorie")
                              .doc(elemen.Sottocategoria.trim())
                              .update({
                                Valore:
                                  firebase.firestore.FieldValue.increment(1),
                              }); //aumenta val sottocateogria
                          } else {
                            db.collection("Users")
                            .doc(user.Email)
                            .collection("Sedi")
                            .doc(elemen.Sede)
                              .collection("Categorie")
                              .doc(elemen.Categoria.trim())
                              .collection("Sottocategorie")
                              .doc(elemen.Sottocategoria.trim())
                              .set({
                                Nome: elemen.Sottocategoria.toString().trim(),
                                Sede: elemen.Sede,
                                Valore: 0,
                              })
                              .then(() => {
                                db.collection("Users")
                                .doc(user.Email)
                                .collection("Sedi")
                                .doc(elemen.Sede)
                                  .collection("Categorie")
                                  .doc(elemen.Categoria.trim())
                                  .collection("Sottocategorie")
                                  .doc(elemen.Sottocategoria.trim())
                                  .update({
                                    Valore:
                                      firebase.firestore.FieldValue.increment(
                                        parseInt(arr.length,10)
                                      ),
                                  }); //aumenta val sottocateogria
                              });
                          }
                        });
                    })
                    .then(() => {
                      db.collection("Users")
                      .doc(user.Email)
                      .collection("Sedi")
                      .doc(elemen.Sede)
                        .update({
                          Ele: firebase.firestore.FieldValue.increment(1),
                        }); //aumenta ele sede

                      const docRef = db.collection("Elementi").doc();

                      docRef.set({
                        Sede: elemen.Sede,
                        Code: elemen.Matricola === undefined ? "" : elemen.Matricola,
                        Descrizione:
                          elemen.Descrizione === undefined
                            ? ""
                            : elemen.Descrizione,
                        ID: elemen.ID_TESTUALE.trim(),
                        Nome: elemen.ID_TESTUALE.trim(),
                        Posizione:
                          elemen.Posizione === undefined
                            ? ""
                            : elemen.Posizione,
                        // Matricola:elemen.Matricola === undefined ? "" : elemen.Matricola,
                        Data_Inst:
                          elemen.Data_Inst === undefined
                            ? ""
                            : elemen.Data_Inst,
                        Data_Scad:
                          elemen.Data_Scad === undefined
                            ? ""
                            : elemen.Data_Scad,
                        Categoria:
                          elemen.Categoria === undefined
                            ? ""
                            : elemen.Categoria.trim(),
                        Macrocategoria:
                          elemen.Sottocategoria === undefined
                            ? ""
                            : elemen.Sottocategoria.trim(),
                        RFID: elemen.RFID === undefined ? "" : elemen.RFID,
                        BARCODE:
                          elemen.BARCODE === undefined ? "" : elemen.BARCODE,
                          Marca: elemen.Marca === undefined ? "" : elemen.Marca,
                          Shared: '',
                          note:'',
                          Valore:0,
                          Cliente: user.Email,

                      });

                      db.collection("Users")
                      .doc(user.Email)
                      .collection("Sedi")
                      .doc(elemen.Sede)
                        .collection("Categorie")
                        .doc(elemen.Categoria.trim())
                        .collection("Sottocategorie")
                        .doc(elemen.Sottocategoria.trim())
                        .collection("Elementi")
                        .doc(docRef.id)
                        .set({
                          ref: docRef.id,
                        });
                    });

                  if (cont === arr.length) {
                    alert("Operazione completata,aggiornare la pagina");
                  }
                });
              }
            } else {
              alert(
                "Il tuo piano Mysic non ti permette di aggiungere ulteriori elementi, fai un upgrade del tuo piano per poterne aggiungere degli ulteriori."
              );
            }
          });
      } else {
        alert("Attenzione, l'Excel importato non possiede alcun elemento.");
      }
    });
  } //funzione per importare l'excel
  function onBtnClickEx() {
    stampEx();
  } //funzione che triggera stampEx

  function stampEx() {
    var arrData = [];
    if (elementi.length > 0) {
      arrData.push([
        "ID_TESTUALE",
            "Categoria",
            "Sottocategoria",
            "Sede",
            "Mappa",
            "Data_Inst",
            "Data_Scad",
            "Descrizione",
            "Matricola",
            "Marca",
            "RFID",
            "BARCODE",
            "Note",
      ]);

      elementi.forEach((ele) => {
        arrData.push([
          ele.Nome,
              ele.Categoria,
              ele.Macrocategoria,
              ele.Sede,
              ele.Mappa,
              ele.Data_Inst,
              ele.Data_Scad,
              ele.Descrizione,
              ele.Code,
              ele.Marca,
              ele.RFID,
              ele.BARCODE,
              ele.note,
        ]);
      });
      stampEx2(arrData);
    } else {
      console.log(elementi);
    }
  } //funzione per esportare i tuoi elementi, triggera stampEx2
  function stampEx2(arrData) {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(arrData);
    XLSX.utils.book_append_sheet(wb, ws, "Elementi");
    XLSX.writeFile(wb, "Elementi_MYSIC.xlsx");
  } //funzione per esportare i tuoi elementi come excel
  function routeChange(i) {
    if (i === 1) {
      window.open(
        "https://firebasestorage.googleapis.com/v0/b/mysic-817f9.appspot.com/o/utilities%2FExcel_Importazione_Mysic.xlsx?alt=media&token=7ea665da-fff4-41c6-8bde-6f531634f695",
        "_blank"
      );
    } else {
      window.open(
        "https://firebasestorage.googleapis.com/v0/b/mysic-817f9.appspot.com/o/utilities%2FIstruzioni_Excel_Mysic.pdf?alt=media&token=e0e1c8bb-5b64-407e-a1d0-daecebd05427",
        "_blank"
      );
    }
  } //funzione per scaricare l'excel di importazione o le regole di importazione
  function deleteElem(
    ele,
    index
  ) {
    if (window.confirm("Sei sicuro di voler eliminare l'elemento "+ ele.Nome+"?")) {
      var arr = [...elementi];
      arr.splice(index, 1);
  
      db.collection("Users")
        .doc(user.Email)
        .collection("Sedi")
        .doc(ele.Sede)
        .collection("Categorie")
        .doc(ele.Categoria)
        .collection("Sottocategorie")
        .doc(ele.Macrocategoria)
        .update({
          Valore: firebase.firestore.FieldValue.increment(-1),
        }) //decrementa il valore nella sottocategoria
        .then((docz) => {
          db.collection("Users")
          .doc(user.Email)
          .collection("Sedi")
          .doc(ele.Sede)
          .collection("Categorie")
          .doc(ele.Categoria)
          .collection("Sottocategorie")
          .doc(ele.Macrocategoria)
            .collection("Elementi")
            .doc(ele.Id)
            .delete() //elimina il ref dell'elemento dall'utente
            .then((doc) => {
              db.collection("Users")
              .doc(user.Email)
              .collection("Sedi")
              .doc(ele.Sede)
              .collection("Categorie")
              .doc(ele.Categoria)
                .update({
                  Ele: firebase.firestore.FieldValue.increment(-1),
                }) //decrementa il valore ele nella categoria
                .then(() => {
                  db.collection("Users")
                  .doc(user.Email)
                  .collection("Sedi")
                  .doc(ele.Sede)
                    .update({
                      Ele: firebase.firestore.FieldValue.increment(-1),
                    }) //decrementa il valore ele nella sede
                    .then(() => {
                      db.collection("Users")
                      .doc(user.Email)
                      .update({
                          Current_Ele:
                            firebase.firestore.FieldValue.increment(-1),
                        }) //decrementa il valore current ele nell'utente
                        .then(() => {
                          db.collection("Elementi")
                            .doc(ele.Id)
                            .delete()
                            .then(() => {
                              //elimina definitivamente l'elemento
                              sessionStorage.setItem('elementi', JSON.stringify(arr));

                              setElementi(arr);
                              alert("Elemento eliminato.");
                            });
                        });
                    });
                });
            });
        });
    }
  } //funzione per eliminare gli elementi

  const onSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortedElements = React.useMemo(() => {
    let sortableItems = [...elementi];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (typeof a[sortConfig.key] === 'string') {
          return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        } else if (sortConfig.key === 'date') {
          return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        } else {
          return 0;
        }
      });
    }
    return sortableItems;
  }, [elementi, sortConfig]);

  
  const vhToPixels = (vh) => {
    return Math.round(window.innerHeight * (vh / 100));
  };

 // Funzione per ottenere i nomi delle mappe per tutti gli elementi
const getMappaNames = async (elements) => {
  console.log('inizio render mappa')
  var arr = [...mapList]; // Array di mappe già caricate

  for (const ele of elements) {
    if (ele.Mappa && ele.Mappa !== '') {

      // Verifica se la mappa è già presente nell'array
      const find = arr.find((e) => e.Id === ele.Mappa);

      if (!find) {
        // Se non è presente, recupera il nome della mappa dal database
        const mapz = await db.collection('Mappe').doc(ele.Mappa).get();
        if (mapz.exists) {
          arr.push({ Id: mapz.id, Nome: mapz.data().Nome }); // Aggiungi la mappa all'array
        }
      }
    }
  }

  setMapList(arr); // Aggiorna la lista delle mappe una volta che tutte sono state caricate
};

// Funzione per ottenere il nome della mappa in base all'ID
const getMappaNomeById = (mappaId) => {
  const find = mapList.find((e) => e.Id === mappaId);
  return find ? find.Nome : 'Non inserita';
};

const [filterEsitoVerifica,setFilterEsitoVerifica] = useState(0) //0 tutti 1 positivi 2 negativi 3 non eseguiti

function changeFilterEsito(){
  if(filterEsitoVerifica===3){
    setFilterEsitoVerifica(0)
  }else{
    setFilterEsitoVerifica(filterEsitoVerifica+1)
  }
}

function getNEle(){
  return filteredElements?.length;
}

function renderEle(map){
  var nome = map.Nome?map.Nome.toString().toLowerCase():''
  var marca = map.Marca?map.Marca.toString().toLowerCase():''
  var codice = map.Code?map.Code.toString().toLowerCase():''
  var descrizione = map.Descrizione?map.Descrizione.toString().toLowerCase():''

  if((sedeF === "" ||sedeF === "default" || sedeF === null || map.Sede === sedeF) &&
  (categoriaF === "" ||categoriaF === "default" ||
    categoriaF === null ||
    map.Categoria === categoriaF) &&
    (filterEsitoVerifica===0||(filterEsitoVerifica===1&&map.EsitoUltimaScadenza==='Positivo')||
    (filterEsitoVerifica===2&&map.EsitoUltimaScadenza==='Negativo')||(filterEsitoVerifica===3&&(map.EsitoUltimaScadenza===null||map.EsitoUltimaScadenza===undefined)))&&
    (isEleBuilder===0||(isEleBuilder===1&&(map.MyEleFatherName!==''&&map.MyEleFatherName!==null&&map.MyEleFatherName!==undefined))||(isEleBuilder===2&&(map.MyEleFatherName===''||map.MyEleFatherName===null||map.MyEleFatherName===undefined)))&&

  (sottocategoriaF === "" ||sottocategoriaF === "default" ||
    sottocategoriaF === null ||
    map.Macrocategoria===sottocategoriaF)&&(findInput===''||findInput===null||nome.includes(findInput)||marca.includes(findInput)||codice.includes(findInput)||descrizione.includes(findInput))){
      return true
    }else{
      return false
    }
}

  const filteredElements = useMemo(() =>
    
    sortedElements.filter((ele) => renderEle(ele)), 
    [sortedElements, renderEle]
    
  );

    // Funzione per renderizzare una riga specifica
    const renderFilteredRow = ({ index, style }) => {
      const ele = filteredElements[index];
      return (
        <div style={style} key={ele.Id}>
        <div className='table-row'>
          <div style={{ cursor: 'pointer', borderRight: '1px solid lightgrey', width: '15vw', height: '6rem', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '5vw', height: '6rem' }}>
              <img 
                src={ele.Photo ? ele.Photo : getIcon(ele)} 
                alt='doc_image' 
                style={{ width: '3vw', height: '3vw', marginLeft: '1.5vw', marginTop: '1rem', marginBottom: 'auto' }}
              />
            </div>
            <div>
              <Tooltip title={ele.Nome}>
                <h4 
                  onClick={() => history.push(`/elementi/elemento/${ele.Id}`)} 
                  className="medium-text" 
                  style={{ whiteSpace: 'nowrap', color: '#192bc2', fontWeight: 'bold', fontSize: '0.9rem', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '9vw' }}
                >
                  {ele.Nome}
                </h4>
              </Tooltip>
            </div>
          </div>
          <div style={{ borderRight: '1px solid lightgrey', width: '10vw', height: '6rem', display: 'flex', alignItems: 'center' }}>
            <div>
              <Tooltip title={ele.Descrizione ? ele.Descrizione : 'Non inserita'}>
                <h4 className="medium-text" style={{ maxWidth: '9.3vw' }}>
                  {ele.Descrizione ? ele.Descrizione : 'Non inserita'}
                </h4>
              </Tooltip>
              <Tooltip title={`Elemento padre: ${ele.MyEleFatherName || 'Nessuno'}`}>
                <h4 className="medium-text" style={{ maxWidth: '9.3vw' }}>
                  Elemento padre: {ele.MyEleFatherName || 'Nessuno'}
                </h4>
              </Tooltip>
            </div>
          </div>
          <div style={{ borderRight: '1px solid lightgrey', width: '10vw', height: '6rem', display: 'flex', alignItems: 'center' }}>
            <div>
              <h4 className="medium-text" style={{ maxWidth: '9.3vw' }}>
                {ele.Categoria}
              </h4>
            </div>
          </div>
          <div style={{ borderRight: '1px solid lightgrey', width: '10vw', height: '6rem', display: 'flex', alignItems: 'center' }}>
            <div>
              <h4 className="medium-text" style={{ maxWidth: '9.3vw' }}>
                {ele.Macrocategoria}
              </h4>
            </div>
          </div>
          <div style={{ borderRight: '1px solid lightgrey', width: '10vw', height: '6rem', display: 'flex', alignItems: 'center' }}>
            <div>
              <h4 className="medium-text" style={{ maxWidth: '9.3vw' }}>
                {getSedeName(ele.Sede,sedi)}
              </h4>
            </div>
          </div>
          <div style={{ borderRight: '1px solid lightgrey', width: '10vw', height: '6rem', display: 'flex', alignItems: 'center' }}>
            <div>
              <h4 onClick={()=>ele.Mappa&&ele.Mappa!==''?history.push(`/mappecliente/${ele.Cliente}/${ele.Sede}/piani/${ele.Piano}/${ele.Mappa}/visualizemappa`):null}className="medium-text" 
              style={{ maxWidth: '9.3vw',cursor:ele.Mappa&&ele.Mappa!==''?'pointer':'normal',color:ele.Mappa&&ele.Mappa!==''?'#192bc2':'black',
                fontWeight:ele.Mappa&&ele.Mappa!==''?'bold':'normal' }}>
                {user.Current_Ele===elementi.length?getMappaNomeById(ele.Mappa):ele.Mappa?'Caricamento in corso...':'Non inserita'}
              </h4>
            </div>
          </div>
          <div style={{width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                <Tooltip title='Data Ultima Verifica'>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                   <b>U.V:</b> {ele.UltimaScadenza?formatData(ele.UltimaScadenza):'-'}
                 </h4>
                 </Tooltip>
                 <Tooltip title='Esito Ultima Verifica'>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw',marginTop:'5px'}} >
                 <b>E.U.V:</b> <b style={{color:ele.EsitoUltimaScadenza?ele.EsitoUltimaScadenza==='Positivo'?'green':'red':'black'}}>{ele.EsitoUltimaScadenza?ele.EsitoUltimaScadenza:'-'}</b>
                 </h4>
                 </Tooltip>
                 <Tooltip title='Prossima Verifica'>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw',marginTop:'5px'}} >
                 <b>P.V:</b> {ele.ProssimaScadenza?formatData(ele.ProssimaScadenza):'-'}
                 </h4>
                 </Tooltip>

                 </div>
               </div>
          <div style={{ width: '7vw', height: '6rem', display: 'flex', alignItems: 'center' }}>
            <div style={{ marginLeft: 'auto', marginRight: '20px', width: 'fit-content' }}>
              <h4 
                onClick={() => history.push(`/elementi/elemento/${ele.Id}`)} 
                style={{ borderBottom: '1px solid #192bc2', color: '#192bc2', cursor: 'pointer', fontSize: '0.8rem' }}
              >
                Dettagli
              </h4>
              {checkDeleteS(user?.Permissions) && isShared(ele)===false?(
                <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
                  <IconButton onClick={() => deleteElem(ele, index)} style={{ width: 'fit-content' }}>
                    <DeleteIcon style={{ color: '#FF0060' }} />
                  </IconButton>
                </div>
              ):null}
            </div>
          </div>
        </div>
        </div>
      );
    };


  


  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
{user!==null&&user!==null?<Notifiche user={user}/>:null}
            <NavBar/>
       {triggerCond===true?<Condividi page='ele' email={user.Email}
 docs={elementi} setDocs={setElementi} type='cli'  trigger={triggerCond} setTrigger={setTriggerCond}/>:null}

{openExcel === true ? (
                    <ExcelMenu
                      importExcel={importExcel}
                      onBtnClickEx={onBtnClickEx}
                      trigger={openExcel}
                      routeChange={routeChange}
                      setTrigger={setOpenExcel}
                      user={user}
                    />
                  ) : null}
   
            <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
              {elementi?<div>
            <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>ELEMENTI</h4>
      </div>
      {user!==null?<Buttons/>:null}
      <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'75vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'7vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {getNEle()}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Elementi totali'}
          </h4>
          </div>
          <Tooltip title={isEleBuilder===0?'Inseriti e non in MyEleBuilder':isEleBuilder===1?'Inseriti in MyEleBuilder':'Non inseriti in MyEleBuilder'}>
          <IconButton onClick={()=>isEleBuilder===0?setIsEleBuilder(1):isEleBuilder===1?setIsEleBuilder(2):setIsEleBuilder(0)} style={{width:'fit-content',marginLeft:5}}>
          <GridOnIcon style={{color:isEleBuilder===0?'grey':isEleBuilder===1?'green':'red'}}/>
          </IconButton>
          </Tooltip>
          <select
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={sedeF}
                className='input-style'
                onChange={(e) => {
                  setSedeF(e.target.value);
                  setCategoriaF('default');
                 setSottoCategoriaF('default');
                }}
              >
                <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                <option value={""}>Tutte</option>
                {sedi && sedi.length > 0 && sedi.map((sede,n)=>{
                                  return <option key={n} value={sede.Id}>{sede.Nome?sede.Nome!==''?sede.Nome:sede.Comune:sede.Comune}</option>
                              })}
              </select>
             {/* */}
              <select
                className='input-style'
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={categoriaF}
                onChange={(e) => {
                  setCategoriaF(e.target.value);
                  setSottoCategoriaF('default');
                }}
                name="categoria"
              >
                <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                <option value={""}>Tutte</option>
                {categorie&&
                categorie.map((categoria,n) => {
                  if (
                    sedeF === "" ||sedeF === "default" ||
                    sedeF === null ||
                    categoria.Sede===sedeF
                  ) {
                    return (
                      <option key={n} value={categoria.Id}>
                        {categoria.Nome}
                      </option>
                    );
                  }else{
                    return null
                  }
                    
                  })}
              </select>
             {/* */}
              <select
                className='input-style'
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw',width:'fit-content',maxWidth:'10rem'}}
                defaultValue={"default"}
                value={sottocategoriaF}
                onChange={(e) =>
                   setSottoCategoriaF(e.target.value)
                }
                name="cartella"
              >
                <option value={"default"} disabled>
                  {'Seleziona sottocategoria...'}
                </option>
                <option value={""}>Tutte</option>
                {sottocategorie&&sottocategorie.map((cartella,n) => {
                    if (
                    (  categoriaF === "" ||categoriaF === "default" ||
                      categoriaF === null ||
                     cartella.Categoria===categoriaF )&& (sedeF === "" ||sedeF === "default" ||
                     sedeF === null ||  cartella.Sede===sedeF)
                    ) {
                      return (
                        <option key={n} value={cartella.Id}>{cartella.Nome}</option>
                      );
                    }else{
                      return null;
                    }
                  })}
              </select>
              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())
                 }
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca elemento per: nome, descrizione, marca o codice..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton onClick={()=>reGet()} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                // onClick={()=>getEle(sedi,email)}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
          <div style={{marginTop:10,borderRadius:5,width:'75vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}}  onClick={() => onSort('Nome')}>
            <h4 className="header-table" >Elemento {sortConfig.key === 'Nome' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Descrizione')}>
          <h4 className="header-table" >Descrizione {sortConfig.key === 'Descrizione' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Categoria')}>
          <h4 className="header-table" >Categoria {sortConfig.key === 'Categoria' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Macrocategoria')}>
          <h4 className="header-table" >Sottocategoria {sortConfig.key === 'Macrocategoria' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Sede')}>
          <h4 className="header-table" >Sede {sortConfig.key === 'Sede' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Mappa')}>
          <h4 className="header-table" >Mappa {sortConfig.key === 'Mappa' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{width:'10vw',height:'3rem',alignItems:'center',display:'flex',flexDirection:'row'}}>
          <h4 className="header-table" style={{marginTop:0}} >Stato </h4>
          <Tooltip title={ <>
      U.V: Ultima Verifica <br />
      E.U.V: Esito Ultima Verifica <br />
      P.V: Prossima Verifica
    </>}><HelpIcon style={{color:'white',fontSize:'1vw',marginLeft:'5px'}}/></Tooltip>
    <Tooltip title={filterEsitoVerifica===0?'Tutti gli elementi':filterEsitoVerifica===1?'Ultimo esito positivo':filterEsitoVerifica===2?'Ultimo esito negativo':'Verifica non ancora eseguita'}>
      <IconButton onClick={()=>changeFilterEsito(filterEsitoVerifica)} style={{width:'30px',height:'30px',marginLeft:'auto',marginRight:'10px',backgroundColor:filterEsitoVerifica===0?'#192bc2':filterEsitoVerifica===1?'green':filterEsitoVerifica===2?'red':'grey'}}>
            <FireExtinguisherIcon style={{color:'white',fontSize:'1vw'}}/>
      </IconButton>
    </Tooltip>
          </div>
          </div>
          <div style={{overflowY:'auto',height:'74vh'}}>
          {sortedElements.length>0?(<List
          height={vhToPixels(74)} // Altezza della finestra di visualizzazione
          itemCount={filteredElements.length} // Usa il numero di elementi filtrati
          itemSize={100} // Altezza di ogni riga (può essere adattato)
          width={'100%'}
        >
         {renderFilteredRow}
        </List>): <h4 className='normal-text' style={{marginTop:'1rem'}}>Nessun elemento inserito.</h4>}
        </div>
        </div>:<Loading/>}
            </div>
        
        </div>
  )
}


export default Elementi