//navbar offritore
import React, { Component } from "react";
import { MenuItem2 } from "./MenuItem2"; //lista pagine
import "./style.css";
import firebase from "firebase";
import logo from "../images/mysic-lungo2.png";
import IconButton from '@mui/material/IconButton';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { getUtente } from "../EngineClienti/engineClienti";
import { useCurrentCli } from "../../CurrentCliContext";

async function exit(){
  sessionStorage.clear()

  const auth = firebase.auth();
 await auth.signOut().then(() => {
    window.location.href="/"
  });
} //funzione di uscita

// Funzione per gestire il cliente selezionato
const ClienteSelector = (props) => {
  const { currentCli, updateCurrentCli } = useCurrentCli();

  const handleChange = (e) => {
    const selectedCliente = e.target.value;
    console.log(selectedCliente);
    updateCurrentCli(selectedCliente);
    props.setCliente(selectedCliente);
  };

  return (
    <div style={{marginBottom:'0px',borderBottom:'1px solid rgba(132, 139, 200, 0.18)',paddingBottom:'2vh',borderTop:'1px solid rgba(132, 139, 200, 0.18)',paddingTop:'2vh'}}>
      <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>
      <h4 style={{margin:0,fontSize:'14px',marginLeft:'auto',marginRight:'auto',color:'#14213d',fontWeight:'bold',fontFamily:'Poppins'}}>CLIENTE SELEZIONATO:</h4>
    <select
      value={currentCli?currentCli:props.selectedCliente}
      onChange={handleChange}
      style={{ width: '100%',fontSize: '14px', borderRadius: '5px',fontFamily: 'Poppins',
        backgroundColor:'transparent',border:'1px solid #14213d',
       }}
    >
              <option key={0} value={'Tutti'}>Tutti</option>

      {props.clienti?.map(cliente => (
            <option key={cliente.id} value={cliente.email}>{cliente.rag_soc}</option>
          ))}
      {/* Mappa i clienti qui */}
    </select>
    </div>

    </div>
  );
};

class NavBar2 extends Component {

  

  state = { clicked: false };
  constructor() {
    super();
    this.state = { 
      user: JSON.parse(sessionStorage.getItem("currentUser"))||null, 
      clienti: JSON.parse(sessionStorage.getItem("clienti_nav")) || [], 
      selectedCliente: sessionStorage.getItem("selectedCliente") || "" 
    };
  }
  async componentDidMount() {
    const auth = firebase.auth();
    const db = firebase.firestore();
    console.log('ciao')
    if (auth.currentUser) {
      // 🔹 Recupera dati utente usando `getUtente`
      getUtente(db, auth, (userData) => {
        this.setState({ user: userData});
        sessionStorage.setItem("currentUser",  JSON.stringify(userData));


        if(userData.DefaultCli){
          this.setState({selectedCliente: userData.DefaultCli });
          sessionStorage.setItem("selectedCliente",  userData.DefaultCli);

        }

        if (userData) {
          this.fetchClienti(userData.Email);
        }
      });
    }
  }
   // 🔹 Funzione per recuperare i clienti
   async fetchClienti(email) {
    const db = firebase.firestore();
  
    // 🔹 Scarica la lista dei clienti (id ed eventuale nome)
    const clientiSnap = await db.collection('Users')
      .doc(email)
      .collection('Clienti')
      .get();
  
    let clientiList = [];
  
    // 🔹 Itera sui clienti per recuperare il loro profilo completo
    for (const doc of clientiSnap.docs) {
      const clienteEmail = doc.data().email || doc.id; // Usa Email se presente, altrimenti ID
      const clienteRef = db.collection('Users').doc(clienteEmail);
  
      try {
        const clienteData = await clienteRef.get();
        
        if (clienteData.exists) {
          clientiList.push({
            id: doc.id, // ID cliente
            rag_soc: clienteData.data().Nome_Azienda || null, // Nome da profilo utente o da Clienti
            Nome_Azienda: clienteData.data().Nome_Azienda || null,
            email: clienteEmail, // Email del cliente
            tipo: clienteData.data().Tipo || "Sconosciuto", // Aggiungi altri dettagli se servono
          });
        } else {
          console.warn(`⚠️ Profilo non trovato per: ${clienteEmail}`);
          clientiList.push({ id: doc.id, nome: doc.data().Nome || doc.id, email: clienteEmail });
        }
      } catch (error) {
        console.error(`❌ Errore nel recupero del profilo cliente ${clienteEmail}:`, error);
      }
    }
  
    // 🔹 Aggiorna lo stato con i clienti aggiornati
    this.setState({ clienti: clientiList });
    sessionStorage.setItem("clienti_nav",JSON.stringify(clientiList));

  
    // 🔹 Se non c'è un cliente selezionato, imposta il primo della lista
    if (!this.state.selectedCliente && clientiList.length > 0) {
      this.setCliente(clientiList[0].id);
    }
  }

  // 🔹 Cambia cliente selezionato e aggiorna Firestore
  async setCliente(clienteId) {
    this.setState({ selectedCliente: clienteId });
    sessionStorage.setItem("selectedCliente", clienteId);

   
  }



  render() {
    const { user, clienti, selectedCliente } = this.state;


    return (
      <nav
      style={{  zIndex: '11999', position: 'absolute',overflow:'hidden',width:'15vw',height:'100vh'}}
      >

          <div className="NavBarItems2" style={{border:'1px solid rgba(132, 139, 200, 0.18)' }} >
          <a href="/home" >
            <img
              src={logo}
              alt='logo-nav'

              style={{ width:window.innerWidth>1440?"6rem":'6rem',marginLeft:window.innerWidth>1440?'1rem':'1rem',marginTop:'1rem',marginBottom:'1rem'  }}
            />
            </a>
             {/* 🔹 Dropdown per selezionare il cliente */}
             <ClienteSelector clienti={clienti} setCliente={this.setCliente.bind(this)} selectedCliente={selectedCliente} />
          {MenuItem2.map((item, index) => {
              return (
                <div key={index} style={{borderTop:index===6?'1px solid rgba(132, 139, 200, 0.18)': null,paddingTop:index===6?'1vh':null}}>
                
  <a
 style={{
    
  textDecoration: "none",
  fontWeight: "normal",
  borderTopLeftRadius:index===0?0:0
}}

className={window.location.pathname===item.url?item.cName+'2':item.cName}
href={item.url}
>
  {item.title}
</a>
               
                
                </div>
              );
            })}
            
              <IconButton onClick={exit} style={{position: 'absolute', bottom: '1vh', left: '0.5vw', width: 'fit-content',padding:5,borderRadius:10}}>
                <ExitToAppRoundedIcon/><i className="exit" style={{fontSize: '17px', fontFamily: 'Poppins'}}>Logout</i>
              </IconButton>
          </div>
        
      
      </nav>
    );
  }
}
export default NavBar2;
