import React,{useState,useEffect} from 'react'
import NavBar2 from '../NavBar/NavBar2'
import firebase from "firebase";
import { getUtente } from '../EngineClienti/engineClienti';
import { getSediClienti } from '../EngineOffritore/engineOffritore';
import {  IconButton, Tooltip } from '@mui/material';
import LoopIcon from '@material-ui/icons/Loop';
import BusinessIcon from '@mui/icons-material/Business';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Notifiche from '../Notifiche/Notifiche';
import { useCurrentCli } from '../../CurrentCliContext';

const MappeOffritore = ({history}) => {

  const { currentCli,updateCurrentCli } = useCurrentCli();  // Ottieni il cliente selezionato dal Context
  

  const db = firebase.firestore()
  const auth = firebase.auth()

  const [user,setUser] = useState(null)
  const [sedi,setSedi] = useState([])
  const [clienti,setClienti] = useState([])

  const [clienteF,setClienteF] = useState('default')

  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getUtente(db,auth,setUser)
      }
    })
  },[auth])

  useEffect(()=>{
    if(user!==null){
      if(user.DefaultCli&&user.DefaultCli!==''){

        const compare = currentCli?currentCli:user.DefaultCli

      setClienteF(compare)
      }
      getSediClienti(db,user.Email,setClienti,setSedi)
  }
},[user])

      //quando currentcli cambia chiama changeUser
              useEffect(()=>{ 
                 // Carica i dati
                setClienteF(currentCli)
                console.log(currentCli)
            
              },[currentCli])

function reGet (){
  getSediClienti(db,user.Email,setClienti,setSedi)

}
function returnCliente(cliente){
  console.log(cliente)
  if(clienteF===""||clienteF==='Tutti'||clienteF==="default"||(clienteF!=='default'&&clienteF!==''&&cliente.Email===clienteF)){
    return true
  }
}

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
      <NavBar2/>
      {user!==null&&user!==null?<Notifiche user={user}/>:null}

      <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
      <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>MAPPE</h4>
       
      </div>

          <div style={{width:'100%',height:'84vh',overflowY:'auto',paddingTop:'1vh'}}>
            {clienti.length>0?clienti.map((cli,n)=>(
              returnCliente(cli)===true?
              <div style={{marginTop:'2vh'}} key={n}>
              <h4 className='title-section'>{cli.Nome_Azienda}</h4>
              {console.log(cli,sedi)}
              <div style={{marginTop:'1vh',alignItems:'center',display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gridGap: 1}}>
                {sedi.filter((e)=>e.Cliente===cli.Email).map((sede,x)=>(
                  <div key={x} onClick={()=>history.push(`/mappemanutentore/${sede.Cliente}/${sede.Id}/piani`)} className='box-shadow' style={{width:'10vw',height:'10vw',marginTop:'1vh',borderRadius:10,padding:5,cursor:'pointer',backgroundColor:'white'}}>
                    <Tooltip title={sede.Comune}>
                    <div style={{display:'flex',alignItems:'center'}}><BusinessIcon style={{width:'1vw',height:'1vw',color:"#192bc2"}}/>
                    <h4 className='normal-text' style={{color:'#192bc2',maxWidth:'8.6vw',whiteSpace: 'nowrap',overflow: 'hidden',
                    textOverflow: 'ellipsis',fontWeight:'bold'}}>{sede.Nome?sede.Nome!==''?sede.Nome:sede.Comune:sede.Comune}</h4>
                    </div>
                    </Tooltip>
                    <div style={{display:'flex',alignItems:'center'}}><PlaceIcon style={{width:'1vw',height:'1vw',color:"#677483"}}/>
                    <h4 className='sub-text' style={{whiteSpace: 'nowrap',overflow: 'hidden',
                    textOverflow: 'ellipsis',maxWidth:'8.6vw'}}>{sede.Indirizzo}</h4>
                    </div>
                    <div style={{display:'flex',alignItems:'center'}}><LocalPhoneIcon style={{width:'1vw',height:'1vw',color:"#677483"}}/>
                    <h4 className='sub-text' style={{whiteSpace: 'nowrap',overflow: 'hidden',
                    textOverflow: 'ellipsis',maxWidth:'8.6vw'}}>{sede.Numero}</h4>
                    </div>
                    <div style={{display:'flex',alignItems:'center'}}>
                    <h4 className='sub-text' style={{whiteSpace: 'nowrap',overflow: 'hidden',
                    textOverflow: 'ellipsis',maxWidth:'8.6vw'}}>Piani: {sede.Numero_Piani?sede.Numero_Piani:0}</h4>
                    </div>

                  </div>
                ))}
                </div>
            </div>:null
            )):<h4 className='normal-text'>Nessun cliente aggiunto</h4>}
          </div>
    	</div>
    </div>
  )
}

export default MappeOffritore