import React,{useEffect, useState, useMemo} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Icon, IconButton, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import firebase from "firebase";
import { getElementi, getUtente,getIcon,checkDeleteS,checkWriteS } from '../EngineClienti/engineClienti';
import LoopIcon from '@material-ui/icons/Loop';
import '../general.css'
import { useHistory } from "react-router-dom";
import Condividi from '../Popups/Condividi';
import Loading from '../loading';
import * as XLSX from "xlsx/xlsx.mjs";
import DeleteIcon from '@mui/icons-material/Delete';
import { getElementiClienti,getEleCliente } from '../EngineOffritore/engineOffritore';
import NavBar2 from "../NavBar/NavBar2";
import Notifiche from '../Notifiche/Notifiche';
import GridOnIcon from '@mui/icons-material/GridOn';
import { FixedSizeList as List } from 'react-window';
import { formatData } from '../EngineClienti/engineClienti';
import HelpIcon from '@mui/icons-material/Help';

import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisher';
import LoadingTab from '../Caricamenti/LoadingTab';
import { useCurrentCli } from '../../CurrentCliContext';


const ElementiOffritore = () => {

  const { currentCli,updateCurrentCli } = useCurrentCli();  // Ottieni il cliente selezionato dal Context


  const [elementi,setElementi] = useState([]) //lista elementi
  const [categorie,setCategorie] = useState([]) //lista categorie
  const [clienti,setClienti] = useState([]) //lista clienti
  const [sottocategorie,setSottoCategorie] = useState([]) //lista sottocategorie
  const [user,setUser] = useState(null) //info utente 


  ////filtri

  const [clienteF,setClienteF] = useState('default')
  const [categoriaF,setCategoriaF] = useState('default')
  const [sottocategoriaF,setSottoCategoriaF] = useState('default')
  const [findInput,setFindInput] = useState('')

  //// trigger popups

  const [triggerCond,setTriggerCond] = useState(false) //condivisione multipla
  const [openExcel,setOpenExcel] = useState(false) //apri menu excel

  const db = firebase.firestore()
  const auth = firebase.auth()
  const history = useHistory()
  const listaE = JSON.parse(sessionStorage.getItem('elementi'));
  const listaC = JSON.parse(sessionStorage.getItem('categorie'));
  const listaS = JSON.parse(sessionStorage.getItem('sottocategorie'));
  const listaCli = JSON.parse(sessionStorage.getItem('clienti'));

  //sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [isEleBuilder,setIsEleBuilder] = useState(0)

  const [loading,setLoading] = useState(false)


  useEffect(() => {
    if(user!==null){
    if(user.Current_Ele===elementi.length){
      sessionStorage.setItem('elementi', JSON.stringify(elementi));
      sessionStorage.setItem('categorie', JSON.stringify(categorie));
      sessionStorage.setItem('sottocategorie', JSON.stringify(sottocategorie));
      sessionStorage.setItem('clienti', JSON.stringify(clienti));

    }
  }
     
    
  }, [elementi]);


  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getUtente(db,auth,setUser)
      }
    })
  },[auth])


  //prende dati nuovo utente singolo
  function changeUser(){
    console.log('ciao')
    if(user!==null){
      setCategoriaF('default');
      setSottoCategoriaF('default');
      console.log('acciderbolina',user.DefaultCli,currentCli)

     setLoading(true)

     const compare = currentCli?currentCli:user.DefaultCli

     console.log('grammu',currentCli)

      if (compare === "Tutti") {
        console.log("Mostro i dati di tutti i clienti");
  
        getElementiClienti(db, user.Email, setClienti, setElementi, setCategorie, setSottoCategorie)
        setLoading(false)

      }else{
      console.log('zizza')
      // Controlla se i dati del cliente sono già in sessionStorage
      const cachedData = sessionStorage.getItem(`elementiCliente_${compare}`);

      if (cachedData) {
        console.log("Dati caricati dalla cache:", compare);
        const data = JSON.parse(cachedData);
        setClienti(data.clienti);
        setCategorie(data.categorie);
        setElementi(data.elementi);
        setSottoCategorie(data.sottocategorie);
        setLoading(false);
      } else {
        console.log("Scarico i dati dal DB per", compare);
        getEleCliente(db, user.Email, compare).then((data) => {
          setLoading(false);
          setClienti(data.clienti);
          setCategorie(data.categorie);
          setElementi(data.elementi);
          setSottoCategorie(data.sottocategorie);
  
          // Salva i dati nella cache per il cliente selezionato
          sessionStorage.setItem(
            `elementiCliente_${compare}`,
            JSON.stringify(data)
          );
        });
      }
    }
    }
  }

  //controllo se utente selezionato è quello attuale
  function check(){
    console.log(listaE, currentCli)
    const elementiz= [...listaE?listaE:elementi]

    const compare = currentCli?currentCli:user.DefaultCli

    console.log('compare',compare)
    
    const filtered = elementiz.filter(ele => ele.Cliente === compare);
    console.log('filtered',filtered)
    if (filtered.length > 0) {
      console.log('cuay')
      return false
    }else{
      console.log('cefuay')

      return true
    }
  }

  //quando currentcli cambia chiama changeUser
  useEffect(()=>{ 
     // Carica i dati
    changeUser()
    console.log(currentCli)

  },[currentCli])

  //se ce un utente predefinito carica i dati altrimenti prendi tutti
  useEffect(() => {
    if (user !== null) {
      console.log("Avvio caricamento dati utente");
  
      if (user.DefaultCli && user.DefaultCli !== "") {
        console.log("DefaultCli trovato:", user.DefaultCli);
        const checked = check()

        if(checked===true){
          changeUser()
        }else{

          const compare = currentCli?currentCli:user.DefaultCli

  
        const cachedData = sessionStorage.getItem(`elementiCliente_${compare}`);
  
        if (cachedData) {
          console.log("Dati pre-caricati trovati per", compare);
          const data = JSON.parse(cachedData);
          setClienti(data.clienti);
          setCategorie(data.categorie);
          setElementi(data.elementi);
          setSottoCategorie(data.sottocategorie);
        } else {
          console.log("Nessun dato pre-caricato. Scarico dal database...");
          setLoading(true);
          getEleCliente(db, user.Email,compare).then((data) => {
            setLoading(false);
            setClienti(data.clienti);
            setCategorie(data.categorie);
            setElementi(data.elementi);
            setSottoCategorie(data.sottocategorie);
  
            // Salva nella cache
            sessionStorage.setItem(`elementiCliente_${compare}`, JSON.stringify(data));
          });
        } }
      } else {
        console.log("Nessun DefaultCli, scarico tutti i dati");
        getElementiClienti(db, user.Email, setClienti, setElementi, setCategorie, setSottoCategorie);
      }
      
    }
  }, [user]);
  /*useEffect(()=>{
    if(user!==null){
      console.log(1)
      if(user.DefaultCli&&user.DefaultCli!==''){
        console.log(2)

        const cachedData = sessionStorage.getItem(`elementiCliente_${user.DefaultCli}`);


        if(listaE?.length>0&&listaC?.length>0&&listaS?.length>0&&listaCli?.length>0){
          console.log(3)

         // console.log('arruva',user?.DefaultCli,currentCli)
         const checkz = check()
          if(checkz===true){
            changeUser()
            console.log(4)

          }else{
            setCategorie(listaC)
            setElementi(listaE)
            setSottoCategorie(listaS)
            setClienti(listaCli)
            console.log(5)

          }
         
                    } else {
                      console.log(6)

                        // Carica i dati
                        setLoading(true)
                        getEleCliente(db, user.Email, user.DefaultCli).then((data) => {
                         setLoading(false)             
                                   
                                       setClienti(data.clienti);
                                       setCategorie(data.categorie);
                                       setElementi(data.elementi)
                                       setSottoCategorie(data.sottocategorie)
                                       sessionStorage.setItem('elementi', JSON.stringify(data.elementi));
                                       sessionStorage.setItem('categorie', JSON.stringify(data.categorie));
                                       sessionStorage.setItem('sottocategorie', JSON.stringify(data.sottocategorie));
                                       sessionStorage.setItem('clienti', JSON.stringify(data.clienti));
                        });

                    }
      }else{
        if(listaE!==null&&listaC!==null&&listaS!==null){

          if(listaE.length>0&&listaC.length>0&&listaS.length>0&&listaCli.length>0){
            setCategorie(listaC)
            setElementi(listaE)
            setSottoCategorie(listaS)
            setClienti(listaCli)
          }else{
            getElementiClienti(db,user.Email,setClienti,setElementi,setCategorie,setSottoCategorie)
    
          }
        }else{
          getElementiClienti(db,user.Email,setClienti,setElementi,setCategorie,setSottoCategorie)
    
        }
      }
    
  }

  },[user])*/


  const [filterEsitoVerifica,setFilterEsitoVerifica] = useState(0) //0 tutti 1 positivi 2 negativi 3 non eseguiti

function changeFilterEsito(){
  if(filterEsitoVerifica===3){
    setFilterEsitoVerifica(0)
  }else{
    setFilterEsitoVerifica(filterEsitoVerifica+1)
  }
}

function getNEle(){
      return filteredElements?.length;
}

  const Buttons = () =>{

    return(
      <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
        <div>
        <h4 onClick={()=>history.push('/elementimanutentore/aggiungicategoria')}  className='text-buttons' >
                AGGIUNGI CATEGORIA
              </h4>
        </div>
        <div>
        <h4 onClick={()=>history.push('/elementimanutentore/aggiungisottocategoria')}  className='text-buttons' >
                AGGIUNGI SOTTOCATEGORIA
              </h4>
              
        </div>
        <div>
        <h4 onClick={()=>history.push('/elementimanutentore/aggiungielemento')}  className='text-buttons' >
                AGGIUNGI ELEMENTO
              </h4>
              
        </div>
      
      </div>
    )
  }
  function reGet (){
    const compare = currentCli?currentCli:user.DefaultCli

    if(compare==='Tutti'){
      sessionStorage.removeItem(`elementi`);

    }else{
      sessionStorage.removeItem(`elementiCliente_${compare}`);
    }
    changeUser()
  }

  function deleteElem(
    ele,
    index
  ) {
    if (window.confirm("Sei sicuro di voler eliminare l'elemento "+ ele.Nome+"?")) {
      
         // Usa l'ID dell'elemento per eliminarlo dalla lista degli elementi
    setElementi(prevElementi => {
      // Filtra gli elementi rimuovendo quello con l'ID corrispondente
      const updatedElements = prevElementi.filter(e => e.Id !== ele.Id);
      return updatedElements; // React aggiorna lo stato con la lista aggiornata
    });
  
      db.collection("Users")
        .doc(user.Email)
        .collection("Clienti")
        .doc(ele.Cliente)
        .update({
          Ele: firebase.firestore.FieldValue.increment(-1),
        }) //decrementa il valore nel cliente OFF
        .then((docz) => {
          db.collection("Users")
          .doc(user.Email)
          .collection("Clienti")
        .doc(ele.Cliente)
            .collection("Elementi")
            .doc(ele.Id)
            .delete() //elimina il ref dell'elemento dall'utente OFF
            .then((doc) => {
              db.collection("Users")
              .doc(user.Email)
                .update({
                  Current_Ele: firebase.firestore.FieldValue.increment(-1),
                }) //decrementa il valore ele nell utente OFF
                .then(() => {
                  db.collection("Users")
                  .doc(ele.Cliente)
                  .collection("Sedi")
                  .doc(ele.Sede)
                    .update({
                      Ele: firebase.firestore.FieldValue.increment(-1),
                    }) //decrementa il valore ele nella sede CLI
                    .then(() => {
                      db.collection("Users")
                      .doc(ele.Cliente)
                      .update({
                          Current_Ele:firebase.firestore.FieldValue.increment(-1),
                        }) //decrementa il valore current ele nell'utente CLI
                        .then(() => {

                          db.collection("Users")
                  .doc(ele.Cliente)
                  .collection("Sedi")
                  .doc(ele.Sede)
                  .collection('Categorie')
                  .doc(ele.Categoria).update({
                    Ele:firebase.firestore.FieldValue.increment(-1), 
                  }) //decrementa valore su cat CLI
                  .then(()=>{
                    db.collection("Users")
                    .doc(ele.Cliente)
                    .collection("Sedi")
                    .doc(ele.Sede)
                    .collection('Categorie')
                    .doc(ele.Categoria)
                    .collection('Sottocategorie')
                    .doc(ele.Macrocategoria)
                    .update({
                      Valore:firebase.firestore.FieldValue.increment(-1), 
                    }) //decrementa valore su sottocat CLI
                    .then(()=>{
                      db.collection("Users")
                      .doc(ele.Cliente)
                      .collection("Sedi")
                      .doc(ele.Sede)
                      .collection('Categorie')
                      .doc(ele.Categoria)
                      .collection('Sottocategorie')
                      .doc(ele.Macrocategoria)
                      .collection('Elementi')
                      .doc(ele.Id).delete().then(()=>{
                        db.collection("Elementi")
                        .doc(ele.Id)
                        .delete()
                        .then(() => {
                           // Rimuove l'elemento dalla cache corretta
                           let cacheKey = `elementiCliente_${ele.Cliente}`;
                           const cachedData = sessionStorage.getItem(cacheKey);

                           if (cachedData) {
                             let parsedData = JSON.parse(cachedData);

                             // Filtra l'array eliminando l'elemento con lo stesso Id
                             const updatedElementi = parsedData.elementi.filter(item => item.Id !== ele.Id);

                             // Aggiorna solo la proprietà "elementi", mantenendo il resto intatto
                             const updatedCacheData = {
                               ...parsedData,
                               elementi: updatedElementi
                             };

                             // Salva l'oggetto aggiornato nella cache
                             sessionStorage.setItem(cacheKey, JSON.stringify(updatedCacheData));
                           }

                          alert("Elemento eliminato.");
                        });
                      })
                    })
  
                  })

                          
                        });
                    });
                });
            });
        });
    }
  } //funzione per eliminare gli elementi

  function returnNomeAzienda(name){
    

    var arr = [...clienti]
    var index = arr.findIndex((e)=>e.Email===name)


    return clienti[index]?.Nome_Azienda || ''

  }

  const onSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortedElements = React.useMemo(() => {
    console.log('sorted change',sortConfig.key)
    let sortableItems = [...elementi];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (typeof a[sortConfig.key] === 'string') {
          return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        } else if (sortConfig.key === 'date') {
          return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        } else {
          return 0;
        }
      });
    }
    console.log('sortableitems',sortableItems)
    return sortableItems;
  }, [elementi, sortConfig]);


  const filteredElements = useMemo(() => {

    var arr = [...sortedElements]

    console.log('filteredElements changing',arr.filter(renderEle),sortedElements)

    function renderEle(map) {
      if (!elementi.some(e => e.Id === map?.Id)) {
          return false;
      }

      var nome = map.Nome ? map.Nome.toString().toLowerCase() : '';
      var marca = map.Marca ? map.Marca.toString().toLowerCase() : '';
      var codice = map.Code ? map.Code.toString().toLowerCase() : '';
      var sede = map.Sede ? map.Sede.toString().toLowerCase() : '';
      var descrizione = map.Descrizione ? map.Descrizione.toString().toLowerCase() : '';

      if (
          (categoriaF === "" || categoriaF === 'default' || categoriaF === null || map.Categoria === categoriaF) &&
          (filterEsitoVerifica === 0 ||
              (filterEsitoVerifica === 1 && map.EsitoUltimaScadenza === 'Positivo') ||
              (filterEsitoVerifica === 2 && map.EsitoUltimaScadenza === 'Negativo') ||
              (filterEsitoVerifica === 3 && (map.EsitoUltimaScadenza === null || map.EsitoUltimaScadenza === undefined))) &&
          (isEleBuilder === 0 ||
              (isEleBuilder === 1 && (map.MyEleFatherName !== '' && map.MyEleFatherName !== null && map.MyEleFatherName !== undefined)) ||
              (isEleBuilder === 2 && (map.MyEleFatherName === '' || map.MyEleFatherName === null || map.MyEleFatherName === undefined))) &&
          (sottocategoriaF === "" ||
              sottocategoriaF === null || sottocategoriaF === 'default' || map.Macrocategoria === sottocategoriaF) &&
          (findInput === '' || findInput === null ||
              map.SedeName?.toString().toLowerCase().includes(findInput) ||
              nome.includes(findInput) ||
              marca.includes(findInput) ||
              codice.includes(findInput) ||
              sede.includes(findInput) ||
              descrizione.includes(findInput))
      ) {
          return true;
      } else {
          return false;
      }
  }

  // Perform filtering based on `renderEle`
  return arr.filter(renderEle);
 
  
  }, [sortedElements, categoriaF, filterEsitoVerifica, isEleBuilder, sottocategoriaF, findInput,elementi])

    useEffect(()=>{
      console.log('sortedChanged',sortedElements)
    },[sortedElements])

    const renderFilteredRow = ({ index, style }) => {
      const ele = filteredElements[index];     
      return (
        <div style={style} key={ele.Id}>
        <div className='table-row'>
               <div  style={{cursor:'pointer',borderRight:'1px solid lightgrey',width:'15vw',height:'6rem',display:'flex',alignItems:'center'}}>
                 <div style={{width:'5vw',height:'6rem',}}>
                 <img src={ele.Photo!==null&&ele.Photo!==undefined?ele.Photo:getIcon(ele)} alt='doc_image' style={{width:'3vw',height:'3vw',marginLeft:'1.5vw',marginTop:'1rem',marginBottom:'auto'}}></img>
                 </div>
                 
                 <div>
                 <Tooltip title={ele.Nome}>
   
                                         <h4 onClick={()=>history.push(`/elementi/elemento/${ele.Id}`)} className="medium-text" style={{whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
   overflow: 'hidden',
   textOverflow: 'ellipsis',maxWidth:'9vw'}}>
                   {ele.Nome}
                 </h4>
                 </Tooltip>
                   </div>
                 
               </div>
               <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
               <Tooltip title={ele.Descrizione!==''?ele.Descrizione:'Non inserita'}>
   
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                   {ele.Descrizione!==''?ele.Descrizione:'Non inserita'}
                 </h4>
              </Tooltip>
              <Tooltip title={'Elemento padre:'+ ele.MyEleFatherName!==''&&ele.MyEleFatherName!==null&&ele.MyEleFatherName!==undefined?ele.MyEleFatherName:'Nessuno'}>
   
   <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
     Elemento padre: {ele.MyEleFatherName!==''&&ele.MyEleFatherName!==null&&ele.MyEleFatherName!==undefined?ele.MyEleFatherName:'Nessuno'}
   </h4>
   
   </Tooltip>
                 </div>
               </div>
               <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                   {ele.Categoria}
                 </h4>
              
                 </div>
               </div>
               <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                   {ele.Macrocategoria}
                 </h4>
              
                 </div>
               </div>
               <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                 {returnNomeAzienda(ele.Cliente)}
   
                 </h4>
              
                 </div>
               </div>
               <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                 {ele.SedeName?ele.SedeName:ele.Sede}
                 </h4>
              
                 </div>
               </div>
               <div style={{width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                <Tooltip title='Data Ultima Verifica'>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                   <b>U.V:</b> {ele.UltimaScadenza?formatData(ele.UltimaScadenza):'-'}
                 </h4>
                 </Tooltip>
                 <Tooltip title='Esito Ultima Verifica'>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw',marginTop:'5px'}} >
                 <b>E.U.V:</b> <b style={{color:ele.EsitoUltimaScadenza?ele.EsitoUltimaScadenza==='Positivo'?'green':'red':'black'}}>{ele.EsitoUltimaScadenza?ele.EsitoUltimaScadenza:'-'}</b>
                 </h4>
                 </Tooltip>
                 <Tooltip title='Prossima Verifica'>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw',marginTop:'5px'}} >
                 <b>P.V:</b> {ele.ProssimaScadenza?formatData(ele.ProssimaScadenza):'-'}
                 </h4>
                 </Tooltip>

                 </div>
               </div>
               <div style={{width:'7vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div style={{marginLeft:'auto',marginRight:'20px',width:'fit-content'}}>
                 <h4 onClick={()=>history.push(`/elementi/elemento/${ele.Id}`)} style={{borderBottom:'1px solid #192bc2',color:'#192bc2',cursor:'pointer',fontSize:'0.8rem',}} >
                   Dettagli
                 </h4>
                 {checkDeleteS(user.Permissions)===true?<div style={{width:'fit-content',marginLeft:'auto'}}>
                 <IconButton onClick={()=>deleteElem(ele,index)} style={{width:'fit-content'}} >
                 <DeleteIcon style={{color:'#FF0060'}}/>
                   </IconButton>
                   </div>:null}
                   
              
                 </div>
               </div>
     
               </div>
        </div>
      )}

    const vhToPixels = (vh) => {
      return Math.round(window.innerHeight * (vh / 100));
    };

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
       {user!==null?<Notifiche user={user}/>:null}
      <NavBar2/>
       {triggerCond===true?<Condividi page='ele' email={user.Email}
 docs={elementi} setDocs={setElementi} type='cli'  trigger={triggerCond} setTrigger={setTriggerCond}/>:null}
   
            <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
              {elementi?<div>
            <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>ELEMENTI</h4>
        
      </div>
      {user!==null?checkWriteS(user.Permissions)?<Buttons/>:null:null}
      <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'75vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'7vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {elementi.length===0?'0':getNEle()}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Elementi totali'}
          </h4>
          </div>
          <Tooltip title={isEleBuilder===0?'Inseriti e non in MyEleBuilder':isEleBuilder===1?'Inseriti in MyEleBuilder':'Non inseriti in MyEleBuilder'}>
          <IconButton onClick={()=>isEleBuilder===0?setIsEleBuilder(1):isEleBuilder===1?setIsEleBuilder(2):setIsEleBuilder(0)} style={{width:'fit-content',marginLeft:5}}>
          <GridOnIcon style={{color:isEleBuilder===0?'grey':isEleBuilder===1?'green':'red'}}/>
          </IconButton>
          </Tooltip>
    
          <select
  className='input-style'
  style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '1vw' }}
  defaultValue={"default"}
  value={categoriaF}
  onChange={(e) => {
    setCategoriaF(e.target.value);
    setSottoCategoriaF('default');
  }}
  name="categoria"
>
  <option value={"default"} disabled>
    Seleziona categoria...
  </option>
  <option value={""}>Tutte</option>

  {categorie && [...new Set(
    categorie
      .filter((categoria) => 
        clienteF === "" || 
        clienteF === "default" || 
        clienteF === null || 
        categoria.Cliente === clienteF
      )
      .map((categoria) => categoria.Nome) // Prende solo i nomi unici
  )].map((nomeCategoria, n) => (
    nomeCategoria?<option key={n} value={nomeCategoria}>
      {nomeCategoria}
    </option>:null
  ))}
</select>

{/* Sottocategorie */}
<select
  className='input-style'
  style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '1vw', width: 'fit-content', maxWidth: '10rem' }}
  defaultValue={"default"}
  value={sottocategoriaF}
  onChange={(e) => setSottoCategoriaF(e.target.value)}
  name="cartella"
>
  <option value={"default"} disabled>
    Seleziona sottocategoria...
  </option>
  <option value={""}>Tutte</option>

  {sottocategorie && [...new Set(
    sottocategorie
      .filter((cartella) => 
        (categoriaF === "" || categoriaF === "default" || categoriaF === null || cartella.Categoria === categoriaF) &&
        (clienteF === "" || clienteF === "default" || clienteF === null || cartella.Cliente === clienteF)
      )
      .map((cartella) => cartella.Nome) // Prende solo i nomi unici
  )].map((nomeSottocategoria, n) => (
    nomeSottocategoria?<option key={n} value={nomeSottocategoria}>
      {nomeSottocategoria}
    </option>:null
  ))}
</select>

              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())
                 }
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca elemento per: nome, descrizione, marca o codice..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton onClick={()=>reGet()} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                // onClick={()=>getEle(sedi,email)}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
      <div style={{marginTop:10,borderRadius:5,width:'75vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}}  onClick={() => onSort('Nome')}>
            <h4 className="header-table" >Elemento {sortConfig.key === 'Nome' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Descrizione')}>
          <h4 className="header-table" >Descrizione {sortConfig.key === 'Descrizione' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Categoria')}>
          <h4 className="header-table" >Categoria {sortConfig.key === 'Categoria' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Macrocategoria')}>
          <h4 className="header-table" >Sottocategoria {sortConfig.key === 'Macrocategoria' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Cliente')}>
          <h4 className="header-table" >Cliente {sortConfig.key === 'Cliente' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Sede')}>
          <h4 className="header-table" >Sede {sortConfig.key === 'Sede' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{width:'10vw',height:'3rem',alignItems:'center',display:'flex',flexDirection:'row'}}>
          <h4 className="header-table" style={{marginTop:0}} >Stato </h4>
          <Tooltip title={ <>
      U.V: Ultima Verifica <br />
      E.U.V: Esito Ultima Verifica <br />
      P.V: Prossima Verifica
    </>}><HelpIcon style={{color:'white',fontSize:'1vw',marginLeft:'5px'}}/></Tooltip>

    <Tooltip title={filterEsitoVerifica===0?'Tutti gli elementi':filterEsitoVerifica===1?'Ultimo esito positivo':filterEsitoVerifica===2?'Ultimo esito negativo':'Verifica non ancora eseguita'}>
      <IconButton onClick={()=>changeFilterEsito(filterEsitoVerifica)} style={{width:'30px',height:'30px',marginLeft:'auto',marginRight:'10px',backgroundColor:filterEsitoVerifica===0?'#192bc2':filterEsitoVerifica===1?'green':filterEsitoVerifica===2?'red':'grey'}}>
            <FireExtinguisherIcon style={{color:'white',fontSize:'1vw'}}/>
      </IconButton>
    </Tooltip>
          </div>
          </div>
         {loading===true?<LoadingTab/>: <div style={{overflowY:'auto',height:'74vh'}}>
          {filteredElements.length>0?(
        <List
          height={vhToPixels(74)} // Altezza della finestra di visualizzazione
          itemCount={filteredElements.length} // Usa il numero di elementi filtrati
          itemSize={100} // Altezza di ogni riga (può essere adattato)
          width={'100%'}
        >
           {renderFilteredRow}
        </List>
      ) :null}
              {user&&getNEle()===0? <h4 className='normal-text' style={{marginTop:'1rem'}}>Nessun elemento trovato.</h4>:null}

        </div>}
        </div>:<Loading/>}
            </div>
        
        </div>
  )
}


export default ElementiOffritore